import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const FooterEight = () => {
  return (
    <footer className="footer footer-eight">
      {/* Footer Top */}
      <div className="footer-top aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-5 col-md-4 col-sm-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/index">
                    <ImageWithBasePath src="assets/img/logo-02.svg" alt="logo" />
                  </Link>
                </div>
                <div className="footer-contact-info">
                  <p>
                    <span>
                      <Icon.Phone className="standard-feather" />
                    </span>{' '}
                    321 546 8764
                  </p>
                  <p>
                    <span>
                      <Icon.Mail className="standard-feather" />
                    </span>{' '}
                    vimaid@example.com
                  </p>
                  <div className="footer-address">
                    <p>
                      <span>
                        <Icon.MapPin className="standard-feather" />
                      </span>{' '}
                      367 Hillcrest Lane, Irvine, California, United States
                    </p>
                  </div>
                </div>
              </div>
              <div className="social-icon">
                <ul>
                  <li>
                    <Link to="#">
                      <i className="fa-brands fa-facebook" />{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa-brands fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fa-brands fa-linkedin" />
                    </Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu footer-menu-eight">
                <h2 className="footer-title">Support</h2>
                <ul>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/about-us">Contact us</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/blog-grid">Online Chat</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/contact-us">Whatsapp</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/provider-signup">Telegram</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">Ticketing</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">Call Center</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu footer-menu-eight">
                <h2 className="footer-title">About Us</h2>
                <ul>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/blog-grid">Blog</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/contact-us">Careers</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/provider-signup">Jobs</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">In Press</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">Gallery</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu footer-menu-eight">
                <h2 className="footer-title">Top Cities</h2>
                <ul>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/about-us">Contact us</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/blog-grid">Online Chat</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/contact-us">Whatsapp</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/provider-signup">Telegram</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">Ticketing</Link>
                  </li>
                  <li>
                    <ImageWithBasePath
                      src="assets/img/icons/mini-pink-feet.svg"
                      alt="image"
                    />
                    <Link to="/user-signup">Call Center</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu-eight">
                <h2 className="footer-title">Application</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore.
                </p>
                <div className="app-eight-bottom">
                  <Link to="#" className="app-eight-bottom-inner">
                    <ImageWithBasePath src="assets/img/icons/qr-code-eight.svg" alt="image" />
                  </Link>
                  <div className="new-app-eight-content">
                    <Link to="#" className="app-eight-content-inner">
                      <ImageWithBasePath
                        src="assets/img/icons/appstore-eight.svg"
                        alt="image"
                      />
                    </Link>
                    <Link to="#">
                      <ImageWithBasePath
                        src="assets/img/icons/googleplay-eight.svg"
                        alt="image"
                      />
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom footer-bottom-eight">
        <div className="container">
          <div className="copyright">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="copyright-text">
                  <p>Copyright 2023 © Truelysell. All right reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default FooterEight;
