import React, { useState } from 'react';
import * as Icon from 'react-feather';
import Slider from 'react-slick';

import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { all_routes } from '../../../../core/data/routes/all_routes';

const ServiceDetails1 = () => {
  const routes = all_routes;
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  const [open, setOpen] = React.useState(false);

  const one = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const two = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Service Details</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Service Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Service Profile */}
            <div className="col-md-8">
              <div className="serv-profile">
                <h2>Car Repair Services</h2>
                <ul>
                  <li>
                    <span className="badge">Car Wash</span>
                  </li>
                  <li className="service-map">
                    <i className="feather-map-pin" /> Alabama, USA
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="serv-action">
                <ul>
                  <li>
                    <Link to="#">
                      <Icon.Heart size={15} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" data-bs-toggle="tooltip" title="Share">
                      <Icon.Share2 size={15} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <Icon.Printer size={15} />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <Icon.Download size={15} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="service-gal">
                <div className="row gx-2">
                  <div className="col-md-9">
                    <div className="service-images big-gallery">
                      <ImageWithBasePath
                        src="assets/img/services/service-ban-01.jpg"
                        className="img-fluid"
                        alt="img"
                      />
                      <Link
                        to="assets/img/services/service-ban-01.jpg"
                        data-fancybox="gallery"
                        className="btn btn-show"
                        onClick={() => setOpen(true)}
                      >
                        <i className="feather-image me-2" />
                        Show all photos
                      </Link>
                      <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={[
                          { src: '/assets/img/services/service-ban-01.jpg' },
                          { src: '/assets/img/services/service-ban-02.jpg' },
                          { src: '/assets/img/services/service-ban-03.jpg' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="service-images small-gallery">
                      <Link
                        to="assets/img/services/service-ban-02.jpg"
                        data-fancybox="gallery"
                        onClick={() => setOpen(true)}
                      >
                        <ImageWithBasePath
                          src="assets/img/services/service-ban-02.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <span className="circle-icon">
                          <Icon.Plus />
                        </span>
                      </Link>
                    </div>
                    <div className="service-images small-gallery">
                      <Link
                        to="assets/img/services/service-ban-03.jpg"
                        data-fancybox="gallery"
                        onClick={() => setOpen(true)}
                      >
                        <ImageWithBasePath
                          src="assets/img/services/service-ban-03.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <span className="circle-icon">
                          <Icon.Plus />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* /Service Profile */}
          </div>
          <div className="row">
            {/* Service Details */}
            <div className="col-lg-8">
              <div className="service-wrap">
                <h5>Service Details</h5>
                <p>
                  Car wash is a facility used to clean the exterior and, in some
                  cases, the interior of motor vehicles. Car washes can be
                  self-serve, fully automated, or full-service with attendants
                  who wash the vehicle.
                </p>
              </div>
              <div className="service-wrap provide-service">
                <h5>Service Provider</h5>
                <div className="row">
                  <div className="col-md-4">
                    <div className="provide-box">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-02.jpg"
                        className="img-fluid"
                        alt="img"
                      />
                      <div className="provide-info">
                        <h6>Member Since</h6>
                        <div className="serv-review">
                          <i className="fa-solid fa-star" /> <span>4.9 </span>
                          (255 reviews)
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <Icon.User className="react-feather-custom"/>
                      </span>
                      <div className="provide-info">
                        <h6>Member Since</h6>
                        <p>Apr 2020</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <Icon.MapPin className="react-feather-custom"/>
                      </span>
                      <div className="provide-info">
                        <h6>Address</h6>
                        <p>Hanover, Maryland</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <Icon.Mail className="react-feather-custom"/>
                      </span>
                      <div className="provide-info">
                        <h6>Email</h6>
                        <p>thomash@example.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="provide-box">
                      <span>
                        <Icon.Phone className="react-feather-custom"/>
                      </span>
                      <div className="provide-info">
                        <h6>Phone</h6>
                        <p>+1 888 888 8888</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="social-icon provide-social">
                      <ul>
                        <li>
                          <Link to="#" target="_blank">
                            <Icon.Instagram className="react-feather-custom"/>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" target="_blank">
                            <Icon.Twitch className="react-feather-custom"/>{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to="#" target="_blank">
                            <Icon.Youtube className="react-feather-custom"/>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" target="_blank">
                            <Icon.Linkedin className="react-feather-custom"/>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <h5>Gallery</h5>
                  </div>
                  <div className="col-md-6 text-md-end">
                    <div className="owl-nav mynav3" />
                  </div>
                </div>
                <Slider {...two} className="gallery-slide">
                  <div className="gallery-widget">
                    <Link
                      to="assets/img/gallery/gallery-big-01.jpg"
                      data-fancybox="gallery"
                    >
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/gallery/gallery-01.jpg"
                      />
                    </Link>
                  </div>
                  <div className="gallery-widget">
                    <Link
                      to="assets/img/gallery/gallery-big-02.jpg"
                      data-fancybox="gallery"
                    >
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/gallery/gallery-02.jpg"
                      />
                    </Link>
                  </div>
                  <div className="gallery-widget">
                    <Link
                      to="assets/img/gallery/gallery-big-03.jpg"
                      data-fancybox="gallery"
                    >
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/gallery/gallery-03.jpg"
                      />
                    </Link>
                  </div>
                  <div className="gallery-widget">
                    <Link
                      to="assets/img/gallery/gallery-02.jpg"
                      data-fancybox="gallery"
                    >
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/gallery/gallery-02.jpg"
                      />
                    </Link>
                  </div>
                </Slider>
              </div>
              <div className="service-wrap">
                <h5>Video</h5>
                <div id="background-video">
                  <Link
                    className="play-btn"
                    data-fancybox=""
                    to="https://www.youtube.com/watch?v=Vdp6x7Bibtk"
                  >
                    <i className="fa-solid fa-play" />
                  </Link>
                </div>
              </div>
              <div className="service-wrap">
                <h5>Reviews</h5>
                <ul>
                  <li className="review-box">
                    <div className="review-profile">
                      <div className="review-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-02.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <div className="review-name">
                          <h6>Dennis</h6>
                          <p>a week ago</p>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqa. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <div className="recommend-item">
                      <Link to="#">
                        <ImageWithBasePath
                          alt="Image"
                          src="assets/img/icons/reply-icon.svg"
                          className="me-2"
                        />{' '}
                        Reply
                      </Link>
                      <div className="recommend-info">
                        <p>Recommend?</p>
                        <Link to="#">
                          <i className="feather-thumbs-up" /> Yes
                        </Link>
                        <Link to="#">
                          <i className="feather-thumbs-down" /> No
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="review-box">
                    <div className="review-profile">
                      <div className="review-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-03.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <div className="review-name">
                          <h6>Jaime</h6>
                          <p>yesterday | 10:35AM </p>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqa. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <div className="recommend-item">
                      <Link to="#">
                        <ImageWithBasePath
                          alt="Image"
                          src="assets/img/icons/reply-icon.svg"
                          className="me-2"
                        />{' '}
                        Reply
                      </Link>
                      <div className="recommend-info">
                        <p>Recommend?</p>
                        <Link to="#">
                          <i className="feather-thumbs-up" /> Yes
                        </Link>
                        <Link to="#">
                          <i className="feather-thumbs-down" /> No
                        </Link>
                      </div>
                    </div>
                  </li>
                  <li className="review-box">
                    <div className="review-profile">
                      <div className="review-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-07.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <div className="review-name">
                          <h6>Martinez</h6>
                          <p>2 days ago | 14:35PM </p>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqa. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <div className="recommend-item">
                      <Link to="#">
                        <ImageWithBasePath
                          alt="Image"
                          src="assets/img/icons/reply-icon.svg"
                          className="me-2"
                        />{' '}
                        Reply
                      </Link>
                      <div className="recommend-info">
                        <p>Recommend?</p>
                        <Link to="#">
                          <i className="feather-thumbs-up" /> Yes
                        </Link>
                        <Link to="#">
                          <i className="feather-thumbs-down" /> No
                        </Link>
                      </div>
                    </div>
                    <div className="reply-area">
                      <textarea
                        className="form-control mb-0"
                        rows={3}
                        placeholder="Type your response....."
                        defaultValue={''}
                      />
                    </div>
                  </li>
                  <li className="review-box">
                    <div className="review-profile">
                      <div className="review-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-05.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <div className="review-name">
                          <h6>Bradley</h6>
                          <p>1 month ago | 17:35PM </p>
                        </div>
                      </div>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqa. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat
                    </p>
                    <div className="recommend-item">
                      <Link to="#">
                        <ImageWithBasePath
                          alt="Image"
                          src="assets/img/icons/reply-icon.svg"
                          className="me-2"
                        />{' '}
                        Reply
                      </Link>
                      <div className="recommend-info">
                        <p>Recommend?</p>
                        <Link to="#">
                          <i className="feather-thumbs-up" /> Yes
                        </Link>
                        <Link to="#">
                          <i className="feather-thumbs-down" /> No
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="text-center">
                  <Link
                    to={routes.customerReviews}
                    className="btn btn-primary btn-review"
                  >
                    View All Reviews
                  </Link>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="service-wrap">
                    <h5>Related Services</h5>
                  </div>
                </div>
                <div className="col-md-6 text-md-end">
                  <div className="owl-nav mynav" />
                </div>
              </div>
              <Slider {...one} className="related-slider">
                <div className="service-widget mb-0">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-01.jpg"
                      />
                    </Link>
                    <div className="fav-item" key={1}
                      onClick={() => handleItemClick(1)}>
                      <Link to={routes.categories}>
                        <span className="item-cat">Cleaning</span>
                      </Link>
                      <Link to="#" className={`fav-icon ${
                          selectedItems[1] ? 'selected' : ''
                        }`}>
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Electric Panel Repairing Service
                      </Link>
                    </h3>
                    <p>
                      <i className="feather-map-pin" />
                      Montana, USA
                      <span className="rate">
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </p>
                    <div className="serv-info">
                      <h6>
                        $25.00<span className="old-price">$35.00</span>
                      </h6>
                      <Link to={routes.booking1} className="btn btn-book">
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget mb-0">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-02.jpg"
                      />
                    </Link>
                    <div className="fav-item" key={2}
                      onClick={() => handleItemClick(2)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Construction</span>
                      </Link>
                      <Link to="#"  className={`fav-icon ${
                          selectedItems[2] ? 'selected' : ''
                        }`}>
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Toughened Glass Fitting Services
                      </Link>
                    </h3>
                    <p>
                      <i className="feather-map-pin" />
                      Montana, USA
                    </p>
                    <div className="serv-info">
                      <h6>$45.00</h6>
                      <Link to={routes. booking1} className="btn btn-book">
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget mb-0">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-03.jpg"
                      />
                    </Link>
                    <div className="fav-item"  key={1}
                      onClick={() => handleItemClick(1)}>
                      <Link to={routes.categories}>
                        <span className="item-cat">Carpentry</span>
                      </Link>
                      <Link to="#" className={`fav-icon ${
                          selectedItems[1] ? 'selected' : ''
                        }`}>
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Wooden Carpentry Work</Link>
                    </h3>
                    <p>
                      <i className="feather-map-pin" />
                      Montana, USA
                    </p>
                    <div className="serv-info">
                      <h6>$45.00</h6>
                      <Link to={routes.booking1} className="btn btn-book">
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            {/* /Service Details */}
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickybar">
                {/* Service Availability */}
                <div className="card card-provide mb-0">
                  <div className="card-body">
                    <div className="provide-widget">
                      <div className="service-amount">
                        <h5>
                          $150<span>$170</span>
                        </h5>
                        <p className="serv-review">
                          <i className="fa-solid fa-star" /> <span>4.9 </span>
                          (255 reviews)
                        </p>
                      </div>
                      <div className="serv-proimg">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-02.jpg"
                          className="img-fluid"
                          alt="img"
                        />
                        <span>
                          <i className="fa-solid fa-circle-check" />
                        </span>
                      </div>
                    </div>
                    <div className="package-widget">
                      <h5>Available Service Packages</h5>
                      <ul>
                        <li>Full car wash and clean</li>
                        <li>Auto Electrical</li>
                        <li>Pre Purchase Inspection</li>
                        <li>Pre Purchase Inspection</li>
                      </ul>
                    </div>
                    <div className="package-widget pack-service">
                      <h5>Additional Service</h5>
                      <ul>
                        <li>
                          <div className="add-serving">
                            <label className="custom_check">
                              <input type="checkbox" name="rememberme" />
                              <span className="checkmark" />
                            </label>
                            <div className="add-serv-item">
                              <div className="add-serv-img">
                                <ImageWithBasePath
                                  src="assets/img/services/service-09.jpg"
                                  alt="image"
                                />
                              </div>
                              <div className="add-serv-info">
                                <h6>House Cleaning</h6>
                                <p>
                                  <i className="feather-map-pin" /> Alabama, USA
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="add-serv-amt">
                            <h6>$500.75</h6>
                          </div>
                        </li>
                        <li>
                          <div className="add-serving">
                            <label className="custom_check">
                              <input type="checkbox" name="rememberme" />
                              <span className="checkmark" />
                            </label>
                            <div className="add-serv-item">
                              <div className="add-serv-img">
                                <ImageWithBasePath
                                  src="assets/img/services/service-16.jpg"
                                  alt="image"
                                />
                              </div>
                              <div className="add-serv-info">
                                <h6>Air Conditioner Service</h6>
                                <p>
                                  <i className="feather-map-pin" /> Illinois,
                                  USA
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="add-serv-amt">
                            <h6>$500.75</h6>
                          </div>
                        </li>
                        <li>
                          <div className="add-serving">
                            <label className="custom_check">
                              <input type="checkbox" name="rememberme" />
                              <span className="checkmark" />
                            </label>
                            <div className="add-serv-item">
                              <div className="add-serv-img">
                                <ImageWithBasePath
                                  src="assets/img/services/service-07.jpg"
                                  alt="Service"
                                />
                              </div>
                              <div className="add-serv-info">
                                <h6>Interior Designing</h6>
                                <p>
                                  <i className="feather-map-pin" /> California,
                                  USA
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="add-serv-amt">
                            <h6>$500.75</h6>
                          </div>
                        </li>
                        <li>
                          <div className="add-serving">
                            <label className="custom_check">
                              <input type="checkbox" name="rememberme" />
                              <span className="checkmark" />
                            </label>
                            <div className="add-serv-item">
                              <div className="add-serv-img">
                                <ImageWithBasePath
                                  src="assets/img/services/service-03.jpg"
                                  alt="Service Image"
                                />
                              </div>
                              <div className="add-serv-info">
                                <h6>Wooden Carpentry Work</h6>
                                <p>
                                  <i className="feather-map-pin" /> Alabama, USA
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="add-serv-amt">
                            <h6>$354.45</h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="card card-available">
                      <div className="card-body">
                        <div className="available-widget">
                          <div className="available-info">
                            <h5>Service Availability</h5>
                            <ul>
                              <li>
                                Monday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Tuesday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Wednesday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Thursday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Friday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Saturday <span>9:30 AM - 7:00 PM</span>{' '}
                              </li>
                              <li>
                                Sunday{' '}
                                <span className="text-danger">Closed</span>{' '}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="map-grid">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509170.989457427!2d-123.80081967108484!3d37.192957227641294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb9fe5f285e3d%3A0x8b5109a227086f55!2sCalifornia%2C%20USA!5e0!3m2!1sen!2sin!4v1669181581381!5m2!1sen!2sin"
                        style={{ border: 0 }}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="contact-map"
                      />
                    </div>
                    <Link to={routes. booking1} className="btn btn-primary">
                      Book Service
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Service Availability */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails1;
