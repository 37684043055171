export const AbuseData = [
  {
    id: '1',
    provider: 'John Smith',
    reportedByUser: 'Sharon',
    description: 'Lorem ipsum dolor sit',
    date: '28 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-01.jpg',
    img2: '/assets/admin/img/customer/user-03.jpg',
  },
  {
    id: '2',
    provider: 'Johnny',
    reportedByUser: 'Pricilla',
    description: 'Lorem ipsum dolor sit',
    date: '15 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-04.jpg',
    img2: '/assets/admin/img/customer/user-05.jpg',
  },
  {
    id: '3',
    provider: 'Robert',
    reportedByUser: 'Amanda',
    description: 'Lorem ipsum dolor sit',
    date: '13 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-06.jpg',
    img2: '/assets/admin/img/customer/user-02.jpg',
  },
  {
    id: '4',
    provider: 'Sharonda',
    reportedByUser: 'James',
    description: 'Lorem ipsum dolor sit',
    date: '03 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-09.jpg',
    img2: '/assets/admin/img/customer/user-09.jpg',
  },
  {
    id: '5',
    provider: 'John Smith',
    reportedByUser: 'Sharon',
    description: 'Lorem ipsum dolor sit',
    date: '18 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-01.jpg',
    img2: '/assets/admin/img/customer/user-03.jpg',
  },
  {
    id: '6',
    provider: 'Johnny',
    reportedByUser: 'Pricilla',
    description: 'Lorem ipsum dolor sit',
    date: '23 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-04.jpg',
    img2: '/assets/admin/img/customer/user-05.jpg',
  },
  {
    id: '7',
    provider: 'Robert', 
    reportedByUser: 'Amanda',
    description: 'Lorem ipsum dolor sit',
    date: '18 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-06.jpg',
    img2: '/assets/admin/img/customer/user-02.jpg',
  },
  {
    id: '8',
    provider: 'Sharonda',
    reportedByUser: 'James',
    description: 'Lorem ipsum dolor sit',
    date: '15 Sep 2022 16:43:20',
    img1: '/assets/admin/img/customer/user-09.jpg',
    img2: '/assets/admin/img/customer/user-09.jpg',
  },
];
