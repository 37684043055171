import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import FooterSix from './footer-six';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import HomeHeader from '../header/home-header';

const HomeSix = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [date, setDate] = useState(null);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const language = [{ name: 'Tornoto' }, { name: 'Texas' }];
  return (
    <div className="main-wrapper">
      {/* Header */}
      <section className="hero-section-six">
        <HomeHeader type={6} />
        <div className="container">
          <div className="side-social-media-six">
            <ul>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-google" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-linkedin" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-instagram" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-twitter" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fa-brands fa-whatsapp" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="home-banner home-banner-six">
            <div className="row w-100 justify-content-center ">
              <div className="col-lg-6 col-12">
                <div className="home-banner-main">
                  <ImageWithBasePath
                    src="assets/img/banner-six-bg.png"
                    alt="Image"
                    className="img-fluid"
                  />
                  <div className="home-banner-six-bg">
                    <ImageWithBasePath
                      src="assets/img/banner-six-bg2.png"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="home-banner-six-bottom">
                  <div className="home-banner-six-detergent">
                    <ImageWithBasePath
                      src="assets/img/icons/cleaner-detergent.svg"
                      alt="image"
                      className="img-fluid"
                    />
                    <h5>AFFORDABLE &amp; RELIABLE</h5>
                  </div>
                  <h1>
                    Professional Cleaning Service <span>You Can Trust</span>
                  </h1>
                  <p>
                    Our professional cleaning service comes up with a complete
                    solution that makes your space sparkle!
                  </p>
                  <div className="search-box-two search-box-six">
                    <form action="search">
                      <div className="search-input-new line-six">
                        <div className="form-group mb-0">
                          <Dropdown
                            value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.value)}
                            options={language}
                            optionLabel="name"
                            placeholder="Service Type"
                            className="select"
                          />
                        </div>
                      </div>
                      <div className="search-input-new line-two-six">
                        <div className="form-group mb-0">
                          {/* <input
                            type="text"
                            className="form-control datetimepicker"
                            placeholder="Select Date"
                          /> */}
                           <Calendar value={date} onChange={(e: any) => setDate(e.value)} placeholder='Select Date' />
                          <Icon.Calendar className="standard-feather" />
                        </div>
                      </div>
                      <div className="search-btn">
                        <button className="btn search_service" type="submit">
                          Book Now
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-six-side-img">
          <ImageWithBasePath src="assets/img/banner-six-side.png" alt="image" />
        </div>
      </section>
      {/* /Banner Section */}
      {/* Choose Us */}
      <section className="reason-choose-us">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row">
              <div className="col-md-6 col-12 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/cleaner-six.svg"
                    alt="image"
                  />
                  <p>WHY TRUELY SELL?</p>
                </div>
                <h2>
                  Reasons to <span>Choose Us</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="total-client-all">
                <ul className="total-client-half">
                  <li className="total-client-mini">
                    <div className="total-experts-main">
                      <h3>540</h3>
                      <p>
                        Total<span>Experts</span>
                      </p>
                    </div>
                  </li>
                  <li className="total-client-mini">
                    <div className="total-experts-main">
                      <h3>540</h3>
                      <p>
                        Total<span>Experts</span>
                      </p>
                    </div>
                  </li>
                  <li>
                    <ul className="total-client-avatar">
                      <li>
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="User"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="image"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="image"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-09.jpg"
                            alt="User"
                          />
                        </Link>
                      </li>
                      <li className="more-set">
                        <Link to="#;">
                          <Icon.Plus className="standard-feather" />
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div>
                  <p className="total-client-content">
                    Our professional cleaning service comes up with a
                    <span>
                      complete solution that makes your space sparkle!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/quality-cleaning.svg"
                  alt="image"
                />
                <h5>High Quality Cleaning</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/cdn-globe.svg"
                  alt="image"
                />
                <h5>Global Locations</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/technical-support.svg"
                  alt="image"
                />
                <h5>Anytime Support</h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4 col-12">
              <div className="professional-cleaning-main">
                <ImageWithBasePath
                  src="assets/img/icons/money-cash.svg"
                  alt="image"
                />
                <h5>Spend Efficiently</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Choose Us */}
      {/* About Our Company */}
      <section className="about-our-company">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row">
              <div className="col-md-6 col-12 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/window-cleaner-detergent.svg"
                    alt="image"
                  />
                  <p>BEST CLEANING SERVICE</p>
                </div>
                <h2>
                  <span>About </span>Our Company
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="our-company-six">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry&apos;s
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </div>
              <div className="aboutus-companyimg">
                <Link to="#">
                  <ImageWithBasePath
                    src="assets/img/our-company-vd.jpg"
                    alt="img"
                  />
                </Link>
                <Link to="#" data-bs-toggle="modal" data-bs-target="#video">
                  <div className="playicon">
                    <span>
                      <Icon.Play className="standard-feather" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="our-company-ryt">
                <div className="our-company-img">
                  <ImageWithBasePath
                    src="assets/img/our-company-six.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-company-bg">
                  <ImageWithBasePath
                    src="assets/img/our-company-six-bg.png"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-company-first-content">
                  <div className="company-top-content">
                    <p>Call us Today!</p>
                    <h3>+012 345678</h3>
                  </div>
                  <Link to="/contact-us">
                    <Icon.ArrowRight className="standard-feather" />
                  </Link>
                </div>
                <div className="our-company-two-content">
                  <div className="company-two-top-content">
                    <h4>12+</h4>
                    <ImageWithBasePath
                      src="assets/img/icons/trophy.svg"
                      alt="image"
                    />
                  </div>
                  <p>Years Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About Our Company */}
      {/* Get Satisfied with the services Explore All */}
      <section className="satisfied-service-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row align-items-end">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/cleaning-mop.svg"
                    alt="image"
                  />
                  <p>AFFORDABLE &amp; RELIABLE</p>
                </div>
                <h2>
                  Get Satisfied with the <span>services</span>
                </h2>
              </div>
              <div className="col-md-6 aos" data-aos="fade-up">
                <Link to="/categories" className="afford-btn">
                  Explore All
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-1.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Upholstery Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-2.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Apartment Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-3.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Covid Clean-Up</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-4.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Office Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-5.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Move In/ Out Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-5.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Commercial Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-7.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Window Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex">
              <div className="get-service-main w-100">
                <span>
                  <ImageWithBasePath
                    src="assets/img/icons/satisfied-8.svg"
                    alt="image"
                  />
                </span>
                <Link to="/service-details">
                  <h5>Carpet Cleaning</h5>
                </Link>
                <div className="get-service-btn">
                  <Link to="/service-details">
                    View More{' '}
                    <Icon.ChevronRight className="standard-feather ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Get Satisfied with the services Explore All */}
      {/* See How It Works */}
      <section className="see-works-six-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/window-cleaner-detergent-1.svg"
                    alt="image"
                  />
                  <p>HOW WE ARE WORKING?</p>
                </div>
                <h2>
                  See How It <span>Works</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="how-it-works-six">
                <div className="works-six-num">
                  <h2>01</h2>
                </div>
                <div className="work-online-schedule">
                  <div className="work-online-img">
                    <ImageWithBasePath
                      src="assets/img/works-six-1.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="work-online-bottom">
                    <ImageWithBasePath
                      src="assets/img/icons/gui-calendar-planner.svg"
                      alt="image"
                    />
                    <h4>Easy Online Scheduling</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="how-it-works-six">
                <div className="works-six-num works-six-num-two">
                  <h2>02</h2>
                </div>
                <div className="work-online-schedule">
                  <div className="work-online-img">
                    <ImageWithBasePath
                      src="assets/img/works-six-2.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="work-online-bottom work-online-bottom-two">
                    <h4>Get A Certified Cleaner</h4>
                    <ImageWithBasePath
                      src="assets/img/icons/carpet.svg"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="how-it-works-six">
                <div className="works-six-num works-six-num-three">
                  <h2>03</h2>
                </div>
                <div className="work-online-schedule">
                  <div className="work-online-img">
                    <ImageWithBasePath
                      src="assets/img/works-six-3.png"
                      alt="image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="work-online-bottom">
                    <ImageWithBasePath
                      src="assets/img/icons/like-svg.svg"
                      alt="image"
                    />
                    <h4>Enjoy Cleanliness!</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /See How It Works */}
      {/* Latest Blog */}
      <section className="blogs-service-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row align-items-end">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/cleaning-mop.svg"
                    alt="image"
                  />
                  <p>AFFORDABLE &amp; RELIABLE</p>
                </div>
                <h2>
                  Latest <span>Blog</span>
                </h2>
              </div>
              <div className="col-md-6 aos" data-aos="fade-up">
                <Link to="/categories" className="afford-btn">
                  Explore All
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-six aos"
                data-aos="fade-up"
              >
                <div className="service-img">
                  <Link to="/blog-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-60.png"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-six">
                  <div className="latest-blog-six">
                    <div className="latest-blog-content">
                      <h5>
                        15<span>Dec</span>
                      </h5>
                    </div>
                    <div className="latest-profile-name">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-10.jpg"
                        alt="User"
                      />
                      <h6>Annie Oakley</h6>
                    </div>
                  </div>
                  <h5 className="blog-import-service">
                    Importance Of Professional Cleaning Services
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <Link to="/blog-details">Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-six aos"
                data-aos="fade-up"
              >
                <div className="service-img">
                  <Link to="/blog-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-61.png"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-six">
                  <div className="latest-blog-six">
                    <div className="latest-blog-content">
                      <h5>
                        07<span>Apr</span>
                      </h5>
                    </div>
                    <div className="latest-profile-name">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-12.jpg"
                        alt="User"
                      />
                      <h6>Annie Oakley</h6>
                    </div>
                  </div>
                  <h5 className="blog-import-service">
                    Home Vaccum Cleaning Service
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <Link to="/blog-details">Read More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-six aos"
                data-aos="fade-up"
              >
                <div className="service-img">
                  <Link to="/blog-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-62.png"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-six">
                  <div className="latest-blog-six">
                    <div className="latest-blog-content">
                      <h5>
                        02<span>Dec</span>
                      </h5>
                    </div>
                    <div className="latest-profile-name">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-19.jpg"
                        alt="User"
                      />
                      <h6>Annie Oakley</h6>
                    </div>
                  </div>
                  <h5 className="blog-import-service">
                    Glass Cleaning Services
                  </h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <Link to="/blog-details">Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Latest Blog */}
      {/* serve you the best cleaning service! */}
      <section className="our-expert-six-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row align-items-end">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/window-cleaner-detergent.svg"
                    alt="image"
                  />
                  <p>SERVE YOU THE BEST CLEANING SERVICE</p>
                </div>
                <h2>
                  Our Expert <span>Team</span>
                </h2>
              </div>
              <div className="col-md-6 aos" data-aos="fade-up">
                <Link to="/categories" className="afford-btn">
                  Explore All
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="our-expert-six">
                <div className="our-expert-img">
                  <ImageWithBasePath
                    src="assets/img/gallery/gallery-04.jpg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-expert-six-content">
                  <h6>William James</h6>
                  <p>Head In Industrial Cleaning</p>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                  </div>
                  <h5>
                    $30.00<span>/hr</span>
                  </h5>
                  <Link to="#" className="btn btn-primary btn-view-six">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="our-expert-six">
                <div className="our-expert-img">
                  <ImageWithBasePath
                    src="assets/img/gallery/gallery-05.jpg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-expert-six-content">
                  <h6>William James</h6>
                  <p>Head In Industrial Cleaning</p>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                  </div>
                  <h5>
                    $30.00<span>/hr</span>
                  </h5>
                  <Link to="#" className="btn btn-primary btn-view-six">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="our-expert-six">
                <div className="our-expert-img">
                  <ImageWithBasePath
                    src="assets/img/gallery/gallery-06.jpg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-expert-six-content">
                  <h6>William James</h6>
                  <p>Head In Industrial Cleaning</p>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                  </div>
                  <h5>
                    $30.00<span>/hr</span>
                  </h5>
                  <Link to="#" className="btn btn-primary btn-view-six">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="our-expert-six">
                <div className="our-expert-img">
                  <ImageWithBasePath
                    src="assets/img/gallery/gallery-07.jpg"
                    alt="image"
                    className="img-fluid"
                  />
                </div>
                <div className="our-expert-six-content">
                  <h6>William James</h6>
                  <p>Head In Industrial Cleaning</p>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                  </div>
                  <h5>
                    $30.00<span>/hr</span>
                  </h5>
                  <Link to="#" className="btn btn-primary btn-view-six">
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /serve you the best cleaning service! */}
      {/* Customer Reviews */}
      <section className="customer-reviews-six-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row align-items-end">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six">
                  <ImageWithBasePath
                    src="assets/img/icons/cleaning-mop.svg"
                    alt="image"
                  />
                  <p>AFFORDABLE &amp; RELIABLE</p>
                </div>
                <h2>
                  Customer <span>Reviews</span>
                </h2>
              </div>
              <div className="col-md-6 aos" data-aos="fade-up">
                <Link to="/categories" className="afford-btn">
                  Explore All
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="customer-review-main-six">
                <div className="customer-review-top">
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-20.jpg"
                    alt="User"
                  />
                  <h5>Maria Williams</h5>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered.
                  </p>
                </div>
                <div className="customer-review-bottom">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                  </div>
                </div>
                <div className="customer-review-quote">
                  <ImageWithBasePath
                    src="assets/img/icons/reviews-quote.svg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="customer-review-main-six">
                <div className="customer-review-top">
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-18.jpg"
                    alt="User"
                  />
                  <h5>Bella Williams</h5>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered.
                  </p>
                </div>
                <div className="customer-review-bottom">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
                <div className="customer-review-quote">
                  <ImageWithBasePath
                    src="assets/img/icons/reviews-quote.svg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="customer-review-main-six">
                <div className="customer-review-top">
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-11.jpg"
                    alt="User"
                  />
                  <h5>Cristina Williams</h5>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered.
                  </p>
                </div>
                <div className="customer-review-bottom">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div>
                </div>
                <div className="customer-review-quote">
                  <ImageWithBasePath
                    src="assets/img/icons/reviews-quote.svg"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Customer Reviews */}
      {/* See How It Works */}
      <section className="hidden-charge-section">
        <div className="container">
          <div className="section-heading section-heading-six">
            <div className="row justify-content-center text-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <div className="reason-six justify-content-center">
                  <ImageWithBasePath
                    src="assets/img/icons/vacuum-cleaner.svg"
                    alt="image"
                  />
                  <p>NO EXTRA HIDDEN CHARGES!</p>
                </div>
                <h2>
                  Choose Your <span>Plan</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="hidden-charge-main">
                <div className="hidden-charge-img">
                  <ImageWithBasePath
                    src="assets/img/icons/charges-3.svg"
                    alt="image"
                  />
                </div>
                <h4>Standard</h4>
                <ul>
                  <li>Experienced &amp; Trained Cleaner</li>
                  <li>Maintenance Cleaning</li>
                  <li>Insured Liability ans Cleaning</li>
                  <li>Planed Holiday Cover</li>
                  <li>Experienced &amp; Trained Cleaner</li>
                </ul>
                <div className="hidden-charges-bottom-mark">
                  <h1>
                    $15<span>/hr</span>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <Link to="#" className="btn btn-primary btn-charges w-100">
                  Book Now
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="hidden-charge-main hidden-charge-main-two">
                <div className="hidden-charge-img">
                  <ImageWithBasePath
                    src="assets/img/icons/charges-2.svg"
                    alt="image"
                  />
                </div>
                <h4>Premium</h4>
                <ul>
                  <li>Experienced &amp; Trained Cleaner</li>
                  <li>Maintenance Cleaning</li>
                  <li>Insured Liability ans Cleaning</li>
                  <li>Planed Holiday Cover</li>
                  <li>Experienced &amp; Trained Cleaner</li>
                  <li>Maintenance Cleaning</li>
                  <li>Insured Liability ans Cleaning</li>
                </ul>
                <div className="hidden-charges-bottom-mark">
                  <h1>
                    $100<span>/hr</span>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <Link
                  to="#"
                  className="btn btn-primary btn-charges btn-plan w-100"
                >
                  Book Now
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="hidden-charge-main">
                <div className="hidden-charge-img">
                  <ImageWithBasePath
                    src="assets/img/icons/charges-1.svg"
                    alt="image"
                  />
                </div>
                <h4>Enterprise</h4>
                <ul>
                  <li>Experienced &amp; Trained Cleaner</li>
                  <li>Maintenance Cleaning</li>
                  <li>Insured Liability ans Cleaning</li>
                  <li>Planed Holiday Cover</li>
                  <li>Experienced &amp; Trained Cleaner</li>
                </ul>
                <div className="hidden-charges-bottom-mark">
                  <h1>
                    $56<span>/hr</span>
                  </h1>
                  <h6>PER MONTH</h6>
                </div>
                <Link to="#" className="btn btn-primary btn-charges w-100">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* App Section */}
      <section className="app-six-section">
        <div className="container">
          <div className="app-sec app-sec-six">
            <div className="app-sec-main">
              <div className="app-imgs-six-bg">
                <ImageWithBasePath
                  src="assets/img/new-app-six-bg.png"
                  alt="image"
                />
              </div>
              <div className="row align-items-center">
                <div className="col-lg-6 col-12">
                  <div className="appimg-six aos" data-aos="fade-up">
                    <ImageWithBasePath
                      src="assets/img/new-app-img.png"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="app-img-sec">
                      <ImageWithBasePath
                        src="assets/img/new-app-sec-ng.png"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="new-app-six-middle">
                    <div className="heading aos" data-aos="fade-up">
                      <h2>
                        Download <span>Our New App</span>
                      </h2>
                      <p>
                        Aliquam lorem ante, dapibus in, viverra quis, feugiat
                        Phasellus viverra nulla ut metus varius laoreet.Aliquam
                        lorem ante.
                      </p>
                    </div>
                    <div className="new-app-six-bottom aos" data-aos="fade-up">
                      <div className="new-app-six-content">
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/icons/googleplay-six.svg"
                            className="img-fluid new-six-img"
                            alt="img"
                          />
                        </Link>
                        <Link to="#;">
                          <ImageWithBasePath
                            src="assets/img/icons/appstore-six.svg"
                            className="img-fluid"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <Link to="#;">
                        <ImageWithBasePath
                          src="assets/img/icons/scan-six.svg"
                          className="img-fluid"
                          alt="img"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /App Section */}
      {/* Footer */}
      <FooterSix />
      {/* /Footer */}
      {/* Modal */}
      <div className="modal fade modal-content-video" id="video" tabIndex={-1}>
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="video-home">
                <video controls={true} id="promovideo">
                  <iframe src="https://www.youtube.com/embed/ExJZAegsOis" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSix;
