import React from 'react';
import PagesAuthHeader from './common/header';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const PasswordRecovery = () => {
  const routes = all_routes;
  return (
    <>
     <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Password Recovery */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Password Recovery</h3>
                  <p>
                    Enter your email and we will send you a link to reset your
                    password.
                  </p>
                </div>
                <form action={routes.resetPassword}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="johndoe@example.com"
                      />
                    </div>
                  </div>
                  <Link to={routes.resetPassword}
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Submit
                  </Link>
                  <p className="no-acc mt-0">
                    Remember Password ? <Link to={routes.login}>Login</Link>
                  </p>
                </form>
              </div>
            </div>
            {/* /Password Recovery */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
