import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import {
  set_header_data,
  set_toggleSidebar_data,
} from '../../../../core/data/redux/action';
import * as Icon from 'react-feather';
import { AppState, Header } from '../../../../core/models/interface';

type props = {
  type: number;
};

const HomeHeader: React.FC<props> = ({ type }) => {
  const routes = all_routes;
  const location = useLocation();
  const header_data = useSelector((state: Header) => state.header_data);
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar);
  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  const [close, setClose] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState({
    logo: '',
    logoSmall: '',
    logoSvg: '',
  });
  const dispatch = useDispatch();
  const toogle = () => {
    dispatch(set_toggleSidebar_data(toggle_data ? false : true));
  };

  const activeRouterPath = (routesArray: Header) => {
    let checkActive = false;
    checkActive;
    header_data.map((mainMenus: { menu: Header }) => {
      mainMenus.menu.map((menus: Header) => {
        checkActive = location.pathname == menus.routes ? true : false;
      });
    });
    const all_routes: string[] = [];
    routesArray.map((item: Header) => {
      all_routes.push(item.routes);
    });
    return all_routes.includes(location.pathname);
  };

  // useEffect(() => {
  // }, [header_data]);

  const setHeaderData = () => {
    dispatch(set_header_data([]));
  };

  const handleScroll = () => {
    setScrollYPosition(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const routerPath = (pathType: number) => {
    switch (pathType) {
      case 1:
        return { path: routes.homeOne, className: 'header-one' };
        break;
      case 10:
        return { path: routes.homeOne, className: '' };
        break;
      default:
        return { path: routes.homeOne, className: 'header-one' };
        break;
    }
  };
  const renderButtons = (pathType: number) => {
    switch (pathType) {
      case 1:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link
                className="nav-link header-reg"
                to="/authentication/choose-signup"
              >
                Register
              </Link>
            </li>
            <li onClick={setHeaderData} className="nav-item">
              <Link
                className="nav-link header-login"
                to="/authentication/login"
              >
                <Icon.Users className="react-feather-custom me-1 mb-1" />
                Login
              </Link>
            </li>
          </ul>
        );
        break;
      case 2:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-login" to={routes.login}>
                <Icon.Users className="react-feather-custom me-1 mb-1" />{' '}
                Register / Login
              </Link>
            </li>
          </ul>
        );
        break;
      case 3:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-login" to={routes.chooseSignUp}>
                <Icon.Calendar className="react-feather-custom me-1 mb-1" />
                APPOINTMENT
              </Link>
            </li>
            <li className="nav-item">
              <div className="cta-btn">
                <Link className="btn" to={routes.providerSignup}>
                  <Icon.User className="react-feather-custom me-1 mb-1" />
                  REGISTER /
                </Link>
                <Link className="btn ms-1" to={routes.login}>
                  LOGIN
                </Link>
              </div>
            </li>
          </ul>
        );
        break;
      case 4:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-login" to={routes.login}>
                <Icon.Users className="react-feather-custom me-1 mb-1" />
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link header-login" to={routes.chooseSignUp}>
                <Icon.Users className="react-feather-custom me-1 mb-1" />
                Register
              </Link>
            </li>
          </ul>
        );
        break;
      case 5:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-reg" to={routes.chooseSignUp}>
                Register
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link header-login" to={routes.login}>
                <Icon.Users className="react-feather-custom me-1 mb-1" />
                Login
              </Link>
            </li>
          </ul>
        );
        break;
      case 6:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-button-six" to="choose-signup">
                <Icon.UserPlus className="standard-feather me-2" />
                Register
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link header-button-six" to="login">
                <Icon.LogIn className="standard-feather me-2" />
                Login
              </Link>
            </li>
          </ul>
        );
        break;
      case 7:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <a className="nav-link header-login" href={routes.chooseSignUp}>
                <Icon.User className="react-feather-custom me-2" />
                Register
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link header-login" href={routes.login}>
                <Icon.User className="react-feather-custom me-2" />
                Login
              </a>
            </li>
          </ul>
        );
        break;
      case 8:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link className="nav-link header-login" to="/login">
                <Icon.LogIn className="standard-feather ms-2" />
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link header-login" to="/choose-signup">
                <Icon.UserPlus className="standard-feather ms-2" />
                Register
              </Link>
            </li>
          </ul>
        );
        break;
      case 9:
        return (
          <ul className="nav header-navbar-rht header-navbar-rht-nine ">
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Become A Professional
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Become A User
              </Link>
            </li>
          </ul>
        );
        break;
      case 10:
        return (
          <ul className="nav header-navbar-rht noti-pop-detail">
            {/* User Menu */}
            <li className="nav-item dropdown has-arrow account-item">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                role="button"
              >
                <i className="feather-globe me-1" /> <span>EN</span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end emp">
                <Link className="dropdown-item" to={routes.customerProfile}>
                  <i className="feather-user me-2" /> Profile
                </Link>
                <Link className="dropdown-item" to={routes.homeOne}>
                  <i className="feather-log-out me-2" /> Logout
                </Link>
              </div>
            </li>
            {/* /Language */}
            {/* Language */}
            <li className="nav-item flag-nav dropdown">
              <div className="flag-dropdown">
                <Link
                  className="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                  to="#"
                  role="button"
                >
                  <ImageWithBasePath
                    src="assets/img/flags/us.png"
                    className="rounded-circle me-1"
                    height={16}
                    alt="Flag"
                  />{' '}
                  <span>USD</span>
                </Link>
                <div className="dropdown-menu">
                  <Link to="#" className="dropdown-item">
                    <ImageWithBasePath
                      src="assets/img/flags/us.png"
                      alt="Flag"
                      height={16}
                    />{' '}
                    USD
                  </Link>
                  <Link to="#" className="dropdown-item">
                    <ImageWithBasePath
                      src="assets/img/flags/fr.png"
                      alt="Flag"
                      height={16}
                    />{' '}
                    Euro
                  </Link>
                </div>
              </div>
            </li>
            {/* /Language */}
            {/* Chat */}
            <li className="nav-item logged-item msg-nav">
              <Link to="customer-chat.html" className="nav-link">
                <ImageWithBasePath
                  src="assets/img/icons/message-icon.svg"
                  alt="Message Icon"
                />
              </Link>
            </li>
            {/* /Chat */}
            {/* Notifications */}
            <li className="nav-item dropdown logged-item noti-nav noti-wrapper">
              <Link
                to="#"
                className="dropdown-toggle nav-link notify-link"
                data-bs-toggle="dropdown"
              >
                <span className="noti-message">1</span>
                <ImageWithBasePath
                  src="assets/img/icons/bell-icon.svg"
                  alt="Bell"
                />
              </Link>
              <div className="dropdown-menu notify-blk notifications">
                <div className="topnav-dropdown-header">
                  <div>
                    <p className="notification-title">
                      Notifications <span> 3 </span>
                    </p>
                  </div>
                  <Link to="#" className="clear-noti">
                    <i className="fa-regular fa-circle-check" /> Mark all as
                    read{' '}
                  </Link>
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    <li className="notification-message">
                      <Link to="notification.html">
                        <div className="media noti-img d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle img-fluid"
                              alt="User Image"
                              src="assets/img/notifications/avatar-01.jpg"
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              Lex Murphy left 6 comments on Isla Nublar SOC2
                              compliance report
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">1m ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <div className="media noti-img d-flex">
                        <Link to="notification.html">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle img-fluid"
                              alt="User Image"
                              src="assets/img/notifications/avatar-02.jpg"
                            />
                          </span>
                        </Link>
                        <div className="media-body flex-grow-1">
                          <Link to="notification.html">
                            <p className="noti-details">
                              Ray Arnold requested access to UNIX directory tree
                              hierarchy
                            </p>
                          </Link>
                          <p className="noti-time">
                            <span className="notification-time">1 min</span>
                          </p>
                          <div className="notify-btns">
                            <button className="btn btn-secondary">
                              Decline
                            </button>
                            <button className="btn btn-primary">Accept</button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="notification-message">
                      <Link to="notification.html">
                        <div className="media noti-img d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle img-fluid"
                              alt="User Image"
                              src="assets/img/notifications/avatar-03.jpg"
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              Dennis Nedry commented on Isla Nublar SOC2
                              compliance report
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">1m ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                    <li className="notification-message">
                      <Link to="notification.html">
                        <div className="media noti-img d-flex">
                          <span className="avatar avatar-sm flex-shrink-0">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle img-fluid"
                              alt="User Image"
                              src="assets/img/notifications/avatar-04.jpg"
                            />
                          </span>
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">
                              John Hammond created Isla Nublar SOC2 compliance
                              report
                            </p>
                            <p className="noti-time">
                              <span className="notification-time">1m ago</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link to="notification.html">
                    View All Notifications{' '}
                    <ImageWithBasePath
                      src="assets/img/icons/arrow-right-01.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>
            </li>
            {/* /Notifications */}
            {/* User Menu */}
            <li className="nav-item dropdown has-arrow account-item">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
              >
                <div className="user-infos">
                  <span className="user-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-02.jpg"
                      className="rounded-circle"
                      alt="User Image"
                    />
                  </span>
                  <div className="user-info">
                    <h6>John Smith</h6>
                    <p>Demo User</p>
                  </div>
                </div>
              </Link>
              <div className="dropdown-menu dropdown-menu-end emp">
                <Link className="dropdown-item" to="customer-profile.html">
                  <i className="feather-user me-2" /> Profile
                </Link>
                <Link className="dropdown-item" to="index.html">
                  <i className="feather-log-out me-2" /> Logout
                </Link>
              </div>
            </li>
            {/* /User Menu */}
          </ul>
        );
        break;
      default:
        return (
          <ul className="nav header-navbar-rht">
            <li className="nav-item">
              <Link
                className="nav-link header-reg"
                to="/authentication/choose-signup"
              >
                Register
              </Link>
            </li>
            <li onClick={setHeaderData} className="nav-item">
              <Link
                className="nav-link header-login"
                to="/authentication/login"
              >
                <i className="fa-regular fa-circle-user me-2"></i>Login
              </Link>
            </li>
          </ul>
        );
        break;
    }
  };

  useEffect(() => {
    type == 1 || type == 4 || type == 10
      ? setImageUrl({
          logo: 'assets/img/vimaidlogo.png',
          logoSmall: 'assets/img/vimaidlogo-small.png',
          logoSvg: 'assets/img/vimaidlogo.svg',
        })
      : setImageUrl({
          logo: 'assets/img/vimaidlogo.png',
          logoSmall: 'assets/img/vimaidlogo-small.png',
          logoSvg: 'assets/img/vimaidlogo.svg',
        });
  }, [type]);

  return (
    <>
      {/* GERİ SAYIM SAYACININ KODLARI */}
      {/* <div className={` top-bar ${type != 2 || !close ? 'd-none' : ''}`}>
        <h6>50% OFF on Christmas</h6>
        <ul>
          <li>2</li>
          <li>15</li>
          <li>33</li>
          <li>32</li>
        </ul>
        <Link to="#" className="top-close" onClick={() => setClose(false)}>
          <Icon.X />
        </Link>
      </div> */}
      <header
        className={`header ${routerPath(type).className} ${
          scrollYPosition > 100 ? 'fixed' : ''
        }`}
      >
        <div className={` ${type == 3 ? 'container-fluid' : 'container'}`}>
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link onClick={toogle} id="mobile_btn" to="#">
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to={routerPath(type).path} className="navbar-brand logo">
                <ImageWithBasePath
                  src={imageUrl.logo}
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link
                to={routerPath(type).path}
                className="navbar-brand logo-small"
              >
                <ImageWithBasePath
                  src={imageUrl.logoSmall}
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routerPath(type).path} className="menu-logo">
                  <ImageWithBasePath
                    src={imageUrl.logoSvg}
                    className="img-fluid"
                    alt="Vimaid Logo"
                  />
                </Link>
                <Link
                  onClick={toogle}
                  id="menu_close"
                  className="menu-close"
                  to="#"
                >
                  {' '}
                  <i className="fas fa-times" />
                </Link>
              </div>

              <ul className="main-nav">
                {/* categories, aboutus, contactus */}
                <>
                  <li className="has-submenu">
                    <Link to={routes.categories}>Categories</Link>
                  </li>
                  <li className="has-submenu">
                    <Link to={routes.faq}>FAQ</Link>
                  </li>
                  <li className="has-submenu">
                    <Link to={routes.aboutUs}>About Us</Link>
                  </li>
                  <li className="has-submenu">
                    <Link to={routes.contactUs}>Contact Us</Link>
                  </li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                  <li className="empty-space"></li>
                </>
                <li className="login-link">
                  <Link to={routes.chooseSignUp}>Register</Link>
                </li>
                <li className="login-link">
                  <Link to={routes.login}>Login</Link>
                </li>
              </ul>
            </div>
            {renderButtons(type)}
          </nav>
        </div>
      </header>
    </>
  );
};

export default HomeHeader;
