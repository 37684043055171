import React from 'react';
import FooterOne from '../home/home-one/footer-one';
import PagesRoutes from './pages.routes';
import { useLocation } from 'react-router-dom';
import HomeHeader from '../home/header/home-header';

const Pages = () => {
  const location = useLocation();
  console.log('location', location);
  return (
    <>
      {location?.pathname == '/authentication/reset-password' || 
      location?.pathname === '/authentication/user-signup' ||
      location?.pathname === '/authentication/login'||
      location?.pathname === '/authentication/login-email'||
      location?.pathname === '/authentication/phone-otp' ||
      location?.pathname === '/authentication/email-otp' ||
      location?.pathname === '/authentication/password-recovery' ||
      location?.pathname === '/authentication/password-recovery' ||
      location?.pathname === '/authentication/choose-signup' ||
      location?.pathname === '/pages/coming-soon' ||
      location?.pathname === '/pages/installer'||
      location?.pathname === '/pages/maintenance'||
      location?.pathname === '/pages/installer-new' ||
      location?.pathname === '/pages/session-expired' ?
       (
        <></>
      ) : (
        <HomeHeader type={1} />
      )}
      <PagesRoutes />
      {location?.pathname == '/pages/coming-soon' ||
      location?.pathname == '/pages/installer'  ||
      location?.pathname == '/pages/installer-new' ||
      location?.pathname == '/pages/session-expired' ||
      location?.pathname == '/pages/maintenance' ?

      (
        <></>
      ) : (
      <FooterOne />

      )}
      
    </>
  );
};

export default Pages;
