import React from 'react';
import CustomerSideBar from './common/sidebar';
import CustomersRoutes from './customers.routes';
import HomeHeader from '../home/header/home-header';

const Customers = () => {
  return (
    <>
      {/* <CustomerHeader /> */}
      <HomeHeader type={10} />
      <div className="content">
        <div className="container">
          <div className="row">
            {location?.pathname == '/customers/customer-chat' ||
            location?.pathname == '/customers/notification' ||
            location?.pathname == '/customers/invoice' ? (
              <></>
            ) : (
              <CustomerSideBar />
            )}
            <CustomersRoutes />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
