import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const routes = all_routes;
  const [passwords, setPasswords] = useState(['', '']);
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);

  const togglePasswordVisibility = (index: number) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };
  const [password, setPassword] = useState('');
  const [passwordResponce, setPasswordResponce] = useState({
    passwordResponceText: '',
    passwordResponceKey: '',
  });

  const onChangePassword = (password: string) => {
    setPassword(password);
    if (password.match(/^$|\s+/)) {
      setPasswordResponce({
        passwordResponceText: 'Whitespaces are not allowed',
        passwordResponceKey: '',
      });
    } else if (password.length === 0) {
      setPasswordResponce({
        passwordResponceText: '',
        passwordResponceKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponce({
        passwordResponceText: 'Weak. Must contain at least 8 characters',
        passwordResponceKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponce({
        passwordResponceText:
          'Average. Must contain at least 1 upper case and number',
        passwordResponceKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponce({
        passwordResponceText: 'Almost. Must contain a special symbol',
        passwordResponceKey: '2',
      });
    } else {
      setPasswordResponce({
        passwordResponceText: 'Awesome! You have a secure password.',
        passwordResponceKey: '3',
      });
    }
  };
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <p>
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>
                {/* Reset Password Form */}
                <form>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">New Password</label>
                      <div className="pass-group" id="passwordInput">
                        <input
                          type={passwordVisibility[0] ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => onChangePassword(e.target.value)}
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(0)}
                        >
                          {passwordVisibility[0] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                      <div
                        className={`password-strength ${
                          passwordResponce.passwordResponceKey === '0'
                            ? 'poor-active'
                            : passwordResponce.passwordResponceKey === '1'
                            ? 'avg-active'
                            : passwordResponce.passwordResponceKey === '2'
                            ? 'strong-active'
                            : passwordResponce.passwordResponceKey === '3'
                            ? 'heavy-active'
                            : ''
                        }`}
                        id="passwordStrength"
                      >
                        <span id="poor" className="active"></span>
                        <span id="weak" className="active"></span>
                        <span id="strong" className="active"></span>
                        <span id="heavy" className="active"></span>
                      </div>
                      <div
                        id="passwordInfo"
                        className={
                          passwordResponce.passwordResponceKey === '0'
                            ? 'weak'
                            : passwordResponce.passwordResponceKey === '1'
                            ? 'average'
                            : passwordResponce.passwordResponceKey === '2'
                            ? 'good'
                            : passwordResponce.passwordResponceKey === '3'
                            ? 'great'
                            : ''
                        }
                      >
                        {passwordResponce.passwordResponceText}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Confirm Password</label>
                      <div className="pass-group">
                        <input
                          type={passwordVisibility[1] ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={passwords[1]}
                          onChange={(e) => {
                            const updatedPasswords = [...passwords];
                            updatedPasswords[1] = e.target.value;
                            setPasswords(updatedPasswords);
                          }}
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(1)}
                        >
                          {passwordVisibility[1] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={routes.success}
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Save Change
                  </Link>
                </form>
                {/* /Reset Password Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
