export const payout = [
  {
    payoutDate: 'Sep 21, 2022',
    amount: '$100.00',
    refunds: '-$0.00',
    fees: '-$10.00',
    total: '$90.00',
    status: 'Paid',
    paymentProcessed: 'Sep 11, 2022',
    color: true,
  },
  {
    payoutDate: 'Sep 21, 2022',
    amount: '$100.00',
    refunds: '-$0.00',
    fees: '-$10.00',
    total: '$90.00',
    status: 'Paid',
    paymentProcessed: 'Sep 11, 2022',
    background: true,
  },
  {
    payoutDate: 'Sep 21, 2022',
    amount: '$100.00',
    refunds: '-$0.00',
    fees: '-$10.00',
    total: '$90.00',
    status: 'Paid',
    paymentProcessed: 'Sep 11, 2022',
  },
  {
    payoutDate: 'Sep 21, 2022',
    amount: '$100.00',
    refunds: '-$0.00',
    fees: '-$10.00',
    total: '$90.00',
    status: 'Paid',
    paymentProcessed: 'Sep 11, 2022',
  },
  {
    payoutDate: 'Sep 26, 2022',
    amount: '$100.00',
    refunds: '-$10.00',
    fees: '-$10.00',
    total: '$90.00',
    status: 'Pending',
    paymentProcessed: 'Sep 11, 2022',
    background: true,
    color: true,
  },
];
