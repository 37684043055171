import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const footerTwo = () => {
  const routes = all_routes;

  return (
    <footer className="footer footer-four">
      {/* Footer Top */}
      <div className="footer-top footer-top-four aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-widget-four">
                <div className="footer-logo">
                  <Link to={routes.homeOne}>
                    <ImageWithBasePath src="assets/img/logo.svg" alt="logo" />
                  </Link>
                </div>
                <div className="footer-content footer-content-four">
                  <p>Our Experts ae available 24/7</p>
                </div>
                <div className="footer-selects">
                  <div className="footer-expert">
                    <p>
                      <Icon.Smartphone size={20} className='me-3'/>
                      1800 - 672-4399
                    </p>
                    <p>
                      <Icon.Mail size={20} className='me-3'/>
                      mail@vimaid.com
                    </p>
                  </div>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title footer-title-four">Pages</h2>
                <ul>
                  <li>
                    <Link to={routes.homeFour}>Home</Link>
                  </li>
                  <li>
                    <Link to="#">For business</Link>
                  </li>
                  <li>
                    <Link to="#">Dry wash</Link>
                  </li>
                  <li>
                    <Link to={routes.contactUs}>Contact</Link>
                  </li>
                  <li>
                    <Link to={routes.blogGrid}>Our Blog</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title footer-title-four">About us</h2>
                <ul>
                  <li>
                    <Link to={routes.aboutUs}>About</Link>
                  </li>
                  <li>
                    <Link to={routes.serviceGrid}>Services</Link>
                  </li>
                  <li>
                    <Link to={routes.howItWorks}>How we work</Link>
                  </li>
                  <li>
                    <Link to="#">News</Link>
                  </li>
                  <li>
                    <Link to={routes.maintenance}>Help Guide</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title footer-title-four">Resources</h2>
                <ul>
                  <li>
                    <Link to="#">Download</Link>
                  </li>
                  <li>
                    <Link to="#">Help Center</Link>
                  </li>
                  <li>
                    <Link to="#">Guides</Link>
                  </li>
                  <li>
                    <Link to="#">Partner Network</Link>
                  </li>
                  <li>
                    <Link to="#">Guide</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <h2 className="footer-subtitle footer-subtitle-four">
                  Newsletter Signup
                </h2>
                <div className="subscribe-form">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                  />
                  <button type="submit" className="btn footer-btn">
                    <Icon.Send size={20}/>
                  </button>
                </div>
                <h2 className="footer-title footer-title-four">Follow Us</h2>
                <div className="social-icon social-icon-four">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook" />{' '}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter" />{' '}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          {/* Copyright */}
          <div className="copyright copyright-four">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="copyright-text copyright-text-four">
                  <p className="mb-0">
                    © 2023 Truelysell. All Rights Reserved.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                {/* Copyright Menu */}
                <div className="copyright-menu">
                  <ul className="policy-menu policy-menu-four">
                    <li>
                      <Link to={routes.homeFour}>Home</Link>
                    </li>
                    <li>
                      <Link to="#">Site Map</Link>
                    </li>
                    <li>
                      <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to={routes.termsCondition}>Cookie Policy</Link>
                    </li>
                  </ul>
                </div>
                {/* /Copyright Menu */}
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default footerTwo;
