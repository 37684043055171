import React from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ContactUs = () => {
  const routes = all_routes;
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Contact Us</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          {/* Contact Details */}
          <div className="contact-details">
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Phone />
                  </span>
                  <div className="contact-data">
                    <h4>Phone Number</h4>
                    <p>
                      <a href="https://wa.me/+447445438041">
                        <i
                          className="fab fa-whatsapp"
                          style={{
                            marginRight: '8px',
                            color: '#25D366',
                            fontSize: '20px',
                          }}
                        ></i>
                        (+44) 7445 438041
                      </a>
                    </p>
                    <p>
                      <a href="tel:+447445438041">
                        <i
                          className="fas fa-phone"
                          style={{
                            marginRight: '8px',
                            color: '#000',
                            fontSize: '14px',
                          }}
                        ></i>
                        (+44) 7445 438041
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.Mail />
                  </span>
                  <div className="contact-data">
                    <h4>Email Address</h4>
                    <p>
                      <Link to="mailto:info@vimaid.com">
                        info@vimaid.com
                      </Link>
                    </p>
                    <p>
                      <Link to="mailto:muhammed@vimaid.com">
                        muhammed@vimaid.com
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 d-flex">
                <div className="contact-info flex-fill">
                  <span>
                    <Icon.MapPin />
                  </span>
                  <div className="contact-data">
                    <h4>Address</h4>
                    <p>71-75 SHELTON STREET COVENT GARDEN <br/>LONDON UNITED KINGDOM <br />WC2H 9JQ</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Contact Details */}
          {/* Get In Touch */}
          <div className="row">
            <div className="col-md-6">
              <div className="contact-img">
                <ImageWithBasePath
                  src="assets/img/contact.jpg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-queries">
                <h2>Get In Touch</h2>
                <form action={routes.contactUs}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name*"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Enter Email Address*"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="col-form-label">Phone Number</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Phone Number"
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Message</label>
                        <textarea
                          className="form-control"
                          rows={4}
                          placeholder="Type Message"
                          defaultValue={''}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button className="btn btn-primary" type="submit">
                        Send Message
                        <Icon.ArrowRightCircle className="standard-feather ms-2" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* /Get In Touch */}
        </div>
      </div>
      {/* Map */}
      <div className="map-grid">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=71-75+SHELTON+STREET+COVENT+GARDEN+LONDON+UNITED+KINGDOM+WC2H+9JQ&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          style={{ border: 0 }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="contact-map"
        />
      </div>
      {/* /Map */}
    </>
  );
};

export default ContactUs;
