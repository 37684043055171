import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import OtpInput from 'react-otp-input';


const PhoneOtp = () => {
  
  const routes = all_routes;
   
    const [otp, setOtp] = useState('');

  return (
    <>
    <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Phone Otp Form */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap otp-wrap">
                <div className="otp-img">
                  <ImageWithBasePath src="assets/img/icons/phone-otp.svg" alt="image" />
                </div>
                <div className="login-header">
                  <h3>Phone OTP Verification</h3>
                  <p>
                    OTP sent to your mobile number ending{' '}
                    <span>******9575</span>
                  </p>
                </div>
                <form action={routes.loginphone}>
                  <div className="form-group">
                    <div className="d-flex passcode-wrap digit-group passcode-verified">
                    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderSeparator={<span> </span>}
      renderInput={(props) => <input {...props} />}
    />
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="time-expiry">
                      <i className="feather-clock me-1" />
                      00:25 secs
                    </div>
                  </div>
                  <p className="no-acc">
                    Did not get the OTP? <Link to="#">Resend OTP</Link>
                  </p>
                  <Link to={routes.login}
                    className="btn btn-primary w-100 login-btn mb-0"
                    type="submit"
                  >
                    Verify &amp; Proceed
                  </Link>
                </form>
              </div>
            </div>
            {/* /Phone Otp Form */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneOtp;
