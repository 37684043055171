import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Howitworks = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">How It Works</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    How It Works
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          {/* How it works*/}
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-acc">
                <span>01</span>
                <h1>Create An Account</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco ut
                  aliquip ex ea commodo consequat.{' '}
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/work-01.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4  order-last order-md-first">
              <div className="work-img">
                <ImageWithBasePath
                  src="assets/img/work-02.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="work-wrap work-wrap-post">
                <span>02</span>
                <h1>Post An Ad</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco ut
                  aliquip ex ea commodo consequat.{' '}
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="work-wrap work-wrap-earning mb-0">
                <span>03</span>
                <h1>Start Earning</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco ut
                  aliquip ex ea commodo consequat.{' '}
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.{' '}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-img mb-0">
                <ImageWithBasePath
                  src="assets/img/work-03.png"
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
          {/* /How it works */}
        </div>
      </div>
    </>
  );
};

export default Howitworks;
