import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';

const ProviderLoginActivity = () => {
  const data = useSelector(
    (state: any) => state.providerlogin_activity,
  );
  const [selectedLanguage, setselectedLanguage] = useState();

  const category = [{ name: 'A -&gt; Z' }, { name: 'Most Helpful' }];
  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* Login Activity */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <div className="provider-subtitle">
                        <h6>Login Activity</h6>
                      </div>
                    </div>
                    <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                      <div className="review-sort me-2">
                        <p>Sort</p>
                        <div>
                          <Dropdown
                            value={selectedLanguage}
                            onChange={(e) => setselectedLanguage(e.value)}
                            options={category}
                            optionLabel="name"
                            placeholder="A -&gt; Z"
                            className="select service-select"
                          />
                        </div>
                      </div>
                      <div className="grid-listview">
                        <ul>
                          <li>
                            <Link to="#">
                              <Icon.Filter />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="provide-table manage-table">
                  <div className="table-responsive">
                    <table
                      className="table custom-table datatable mb-0"
                      id="data-table"
                    >
                      <thead className="thead-light">
                        {/* <tr>
                  <th>Device</th>
                  <th>Date</th>
                  <th>IP Address</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr> */}
                      </thead>
                      <tbody>
                        <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                          value={data}
                          paginator
                          rows={10}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          tableStyle={{ minWidth: '50rem' }}
                        >
                          <Column sortable field="date" header="Date"></Column>
                          <Column
                            sortable
                            field="device"
                            header="Device"
                          ></Column>
                          <Column
                            sortable
                            field="ipAddress"
                            header="IP Address"
                          ></Column>
                          <Column
                            sortable
                            field="location"
                            header="Location"
                          ></Column>
                          <Column
                            sortable
                            field="status"
                            header="Status"
                            className="badge-success"
                          ></Column>
                        </DataTable>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div id="tablelength" />
                  </div>
                  <div className="col-md-7">
                    <div className="table-ingopage">
                      <div id="tableinfo" />
                      <div id="tablepagination" />
                    </div>
                  </div>
                </div>
                {/* /Login Activity */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderLoginActivity;
