import React from 'react'
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
const routes = all_routes;
const ProviderServiceList = () => {
  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
        <div className="row">
          <div className="col-md-4">						
            <div className="provider-subtitle">
              <h6>My Services</h6>
            </div>
          </div>
          <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
            <div className="review-sort me-2">
              <p>Sort</p>
              <select className="select">
                <option>A -&gt; Z</option>
                <option>Most Helpful</option>
              </select>
            </div>
            <div className="grid-listview me-2">
              <ul>
                <li>
                  <Link to="#">
                    <Icon.Filter className="react-feather-icon" />
                  </Link>
                </li>
                <li>
                  <Link to={routes.providerService} className="active">
                  <Icon.Grid className="react-feather-icon" />
                  </Link>
                </li>
                <li>
                  <Link to={routes. providerServiceList}>
                  <Icon.List className="react-feather-icon" />
                  </Link>
                </li>
              </ul>
            </div>
            <Link to={routes.createService} className="btn btn-primary add-set"><Icon.Plus className="react-feather-icon me-2" />Add Service</Link>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      <div className="row">
        <div className="col-md-12">
          <div className="tab-list">
            <ul className="nav">
              <li>
                <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#active-service">Actice Services</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#inactive-service">Inactive Services</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="tab-content pt-0">				
        <div className="tab-pane active" id="active-service">				
          <div className="row">	
            <div className="col-md-12">			
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-04.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Car Wash</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Car Repair Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Maryland City, MD, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}	
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-02.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Construction</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Toughened Glass Fitting Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />New Jersey, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}	
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-06.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Computer</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Computer &amp; Server AMC Service</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />California, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-07.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Interior</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Interior Designing</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Maryland, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-01.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Electrician</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Electric Panel Repairing Service</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Texas, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-09.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Cleaning</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>House Cleaning Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Georgia</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to={routes.providerEditService}><Icon.Edit className="react-feather-icon" />Edit</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><Icon.AlertCircle className="react-feather-icon" />Inactive</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="inactive-service">				
          <div className="row">	
            <div className="col-md-12">	
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-04.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Car Wash</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Car Repair Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Maryland City, MD, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}	
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-02.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Construction</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Toughened Glass Fitting Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />New Jersey, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}	
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-06.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Computer</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Computer &amp; Server AMC Service</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />California, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-07.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Interior</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Interior Designing</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Maryland, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-01.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Electrician</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Electric Panel Repairing Service</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Texas, USA</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
              {/* Favorite Item */}
              <div className="service-list">
                <div className="service-cont">
                  <div className="service-cont-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-09.jpg" />
                    </Link>
                    <div className="fav-item">
                      <Link to="#" className="fav-icon">
                        <Icon.Heart className="react-feather-icon" />
                      </Link>
                    </div>
                  </div>
                  <div className="service-cont-info">
                    <div className="category-rating">
                      <span className="item-cat">Cleaning</span>
                      <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                    </div>
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>House Cleaning Services</Link>
                    </h3>
                    <p><Icon.MapPin className="react-feather-icon" />Georgia</p>
                  </div>
                </div>
                <div className="service-action">
                  <ul>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                    </li>
                    <li>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><Icon.AlertCircle className="react-feather-icon" />Active</Link>
                    </li>
                  </ul>
                  <Link to={routes.booking1} className="btn btn-secondary">Apply Offer</Link>
                </div>
              </div>
              {/* /Favorite Item */}
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="row">
        <div className="col-sm-6">
          <div className="review-entries">
            <span>Show</span>
            <select>
              <option>07</option>
              <option>08</option>
            </select>
            <span>entries</span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="review-pagination">
            <p>1 - 09 of 09</p>
            <ul className="pagination">
              <li className="page-item">
                <Link className="page-link" to="#">1</Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">2 <span className="visually-hidden">(current)</span></Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">3</Link>
              </li>
            </ul>						
          </div>
        </div>
      </div>
      {/* /Pagination */}
    </div>
     {/* Inactive Service */}
     <div className="modal fade custom-modal" id="in-active">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Inactive Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to inactive this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Inactive Service */}
        {/* Active Service */}
        <div className="modal fade custom-modal" id="active">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Active Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to active this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Inactive Service */}
        {/* Delete Service */}
        <div className="modal fade custom-modal" id="del-service">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Delete Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to Delete this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Service */}
  </div>
  )
}

export default ProviderServiceList