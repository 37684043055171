import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Header } from '../../../../core/models/interface';

const HeaderFive = () => {
  const routes = all_routes;
  const header_data = useSelector((state: Header) => state.header_data);
  const [selectedSub, setselectedSub] = useState(null);
  const [selectedAppoinment, setselectedApp] = useState(null);
  const category = [
    { name: 'Job Title' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  const Appoinment = [
    { name: 'Choose Location' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  const activeRouterPath = (routesArray: Header) => {
    const all_routes: string[] = [];
    routesArray.map((item: Header) => {
      all_routes.push(item.routes);
    });
    return all_routes.includes(location.pathname);
  };
  return (
    <>
      <section className="hero-section-five">
        <header className="header header-five">
          <div className="container">
            <nav className="navbar navbar-expand-lg header-nav">
              <div className="navbar-header">
                <Link id="mobile_btn" to="#">
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/index" className="navbar-brand logo">
                  <ImageWithBasePath
                    src="assets/img/logo-02.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link to={routes.homeOne} className="navbar-brand logo-small">
                  <ImageWithBasePath
                    src="assets/img/logo-icon.png"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to={routes.homeOne} className="menu-logo">
                    <ImageWithBasePath
                      src="assets/img/logo-02.svg"
                      className="img-fluid"
                      alt="Logo"
                    />
                  </Link>
                  <Link id="menu_close" className="menu-close" to="#">
                    <i className="fas fa-times" />
                  </Link>
                </div>
                <ul className="main-nav">
                  {header_data.map((item: any, index: number) => {
                    return (
                      <>
                        {item.separateRoute == false && (
                          <li
                            key={index}
                            className={`has-submenu ${
                              item.tittle == 'Home' ? 'megamenu' : ''
                            } ${activeRouterPath(item.menu) ? 'active' : ''} `}
                          >
                            <Link
                              to={''}
                              onClick={() => (item.showAsTab = !item.showAsTab)}
                            >
                              {item.tittle}{' '}
                              <i className="fas fa-chevron-down" />
                            </Link>
                            <ul
                              className={`submenu ${
                                item.tittle == 'Home' ? 'mega-submenu' : ''
                              } ${
                                item.showAsTab == true ? 'show-sub-menu' : ''
                              }`}
                            >
                              {item.menu.map((menu: any) => {
                                return (
                                  <>
                                    {menu.hasSubRoute == false &&
                                      item.tittle != 'Home' && (
                                        <li>
                                          <Link to={menu.routes}>
                                            {menu.menuValue}
                                          </Link>
                                        </li>
                                      )}
                                    {menu.hasSubRoute == true && (
                                      <li className="has-submenu">
                                        <Link
                                          onClick={() =>
                                            (menu.showSubRoute =
                                              !menu.showSubRoute)
                                          }
                                          to={menu.routes}
                                        >
                                          {menu.menuValue}
                                        </Link>
                                        <ul
                                          className={`submenu ${
                                            menu.showSubRoute === true &&
                                            'show-sub-menu'
                                          }`}
                                        >
                                          {menu.subMenus.map(
                                            (
                                              subMenu: Header,
                                              subMenuIndex: number,
                                            ) => {
                                              return (
                                                <li key={subMenuIndex}>
                                                  <Link to={subMenu.routes}>
                                                    {subMenu.menuValue}
                                                  </Link>
                                                </li>
                                              );
                                            },
                                          )}
                                        </ul>
                                      </li>
                                    )}
                                    {menu.menuValue == 'Electrical Home' && (
                                      <div className="megamenu-wrapper">
                                        <div className="row">
                                          {item?.menu.map(
                                            (
                                              menu: Header,
                                              megaIndex: number,
                                            ) => {
                                              return (
                                                <div
                                                  className="col-lg-2"
                                                  key={megaIndex}
                                                >
                                                  <div
                                                    className={`single-demo ${
                                                      menu.routes ==
                                                      location.pathname
                                                        ? 'active'
                                                        : ''
                                                    }`}
                                                  >
                                                    <div className="demo-img">
                                                      <Link to={menu.routes}>
                                                        <ImageWithBasePath
                                                          src={menu.img}
                                                          className="img-fluid"
                                                          alt="img"
                                                        />
                                                      </Link>
                                                    </div>
                                                    <div className="demo-info">
                                                      <Link to={menu.routes}>
                                                        {menu.menuValue}
                                                      </Link>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </li>
                        )}
                      </>
                    );
                  })}
                  <li className="has-submenu">
                    <Link to="/admin/dashboard">Admin</Link>
                  </li>
                  <li className="login-link">
                    <Link to={routes.chooseSignUp}>Appointment</Link>
                  </li>
                  <li className="login-link">
                    <Link to={routes.login}>Login</Link>
                  </li>
                  <li className="login-link">
                    <Link to="#">Register</Link>
                  </li>
                </ul>
              </div>
              <ul className="nav header-navbar-rht">
                <li className="nav-item">
                  <Link
                    className="nav-link header-reg"
                    to={routes.chooseSignUp}
                  >
                    Register
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link header-login" to={routes.login}>
                    <i className="feather-user me-2" />
                    Login
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className="container">
          <div className="home-banner home-banner-three">
            <div className="row align-items-center w-100">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="section-search section-section-five">
                  <h1>
                    The Largest Professional <span>Car</span> Wash
                  </h1>
                  <h4>Search From 150 Awesome Verified Ads!</h4>
                  <p>
                    We value your time and therefore washing your car at our car
                    wash will take no more than 15 minutes.
                  </p>
                  <div className="search-box search-box-five">
                    <form className="search-box-form">
                      <div className="search-input">
                        <div className="search-group-icon search-group-icon-5">
                          <Icon.MapPin />
                        </div>
                        <div className="form-group mb-0">
                          <label>What</label>
                          <div>
                            <Dropdown
                              value={selectedSub}
                              onChange={(e) => setselectedSub(e.value)}
                              options={category}
                              optionLabel="name"
                              placeholder="Job Title"
                              className="select w-100"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="search-input">
                        <div className="search-group-icon search-group-icon-5">
                          <Icon.Search />
                        </div>
                        <div className="form-group mb-0">
                          <label>Where</label>
                          <div>
                            <Dropdown
                              value={selectedAppoinment}
                              onChange={(e) => setselectedApp(e.value)}
                              options={Appoinment}
                              optionLabel="name"
                              placeholder="Choose Location"
                              className="select w-100"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="floating-img">
                  <ImageWithBasePath
                    src="./assets/img/audi.png"
                    alt="audi"
                    className="img-fluid rotate-img"
                  />
                </div>
                <div className="car-five-arrow-img">
                  <ImageWithBasePath
                    src="./assets/img/car-five-arrow.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderFive;
