import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const FooterSeven = () => {
  const routes = all_routes;
  return (
    <footer className="footer footer-seven">
      {/* Footer Top */}
      <div className="footer-top aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-widget-seven">
                <div className="footer-logo">
                  <Link to={routes.homeOne}>
                    <ImageWithBasePath src="assets/img/logo-03.png" alt="logo" />
                  </Link>
                </div>
                <div className="footer-content">
                  <p>
                    We ara a lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor.
                  </p>
                </div>
                <div className="footer-selects footer-selects-seven">
                  <h2 className="footer-subtitle">Newsletter Signup</h2>
                  <div className="subscribe-form">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email"
                    />
                    <button type="submit" className="btn footer-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">Support</h2>
                <ul>
                  <li>
                    <Link to="#">Account</Link>
                  </li>
                  <li>
                    <Link to="#">Book Appointment</Link>
                  </li>
                  <li>
                    <Link to="#">Orders</Link>
                  </li>
                  <li>
                    <Link to="#">Payments</Link>
                  </li>
                  <li>
                    <Link to="#">Returns</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">About</h2>
                <ul>
                  <li>
                    <Link to="#">Account</Link>
                  </li>
                  <li>
                    <Link to="#">Book Appointment</Link>
                  </li>
                  <li>
                    <Link to="#">Orders</Link>
                  </li>
                  <li>
                    <Link to="#">Payments</Link>
                  </li>
                  <li>
                    <Link to="#">Returns</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">Services</h2>
                <ul>
                  <li>
                    <Link to="#">Account</Link>
                  </li>
                  <li>
                    <Link to="#">Book Appointment</Link>
                  </li>
                  <li>
                    <Link to="#">Orders</Link>
                  </li>
                  <li>
                    <Link to="#">Payments</Link>
                  </li>
                  <li>
                    <Link to="#">Returns</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <h2 className="footer-title">Contact</h2>
                <div className="footer-six-main">
                  <div className="footer-six-left">
                    <span className="footer-seven-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/call-calling.svg"
                        alt="image"
                      />
                    </span>
                    <div className="footer-six-ryt">
                      <span>Phone Number</span>
                      <h6>310-437-2766</h6>
                    </div>
                  </div>
                  <div className="footer-six-left">
                    <span className="footer-seven-icon">
                      <ImageWithBasePath src="assets/img/icons/sms.svg" alt="image" />
                    </span>
                    <div className="footer-six-ryt">
                      <span>Mail Address</span>
                      <h6>support@vimaid.com</h6>
                    </div>
                  </div>
                  <div className="footer-six-left ">
                    <span className="footer-seven-icon">
                      <ImageWithBasePath src="assets/img/icons/location.svg" alt="image" />
                    </span>
                    <div className="footer-six-ryt">
                      <span>Address</span>
                      <h6>706 Campfire Ave. Meriden, CT 06450</h6>
                    </div>
                  </div>
                </div>
                <div className="social-icon">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook" />{' '}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter" />{' '}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom footer-bottom-seven">
        <div className="container">
          {/* Copyright */}
          <div className="copyright">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="copyright-text">
                  <p>Copyright 2023 © Truelysell. All right reserved.</p>
                </div>
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default FooterSeven;
