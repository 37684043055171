import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const AboutUs = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const clientSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">About Us</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content p-0">
          {/* About */}
          <div className="about-sec">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <div className="about-exp">
                      <span>4+ years of experiences</span>
                    </div>
                    <div className="abt-img">
                      <ImageWithBasePath
                        src="assets/img/about-01.jpg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <h6>ABOUT OUR COMPANY</h6>
                    <h2>Best Solution For Home Services</h2>
                    <p>
                      At Vimaid Lifetech Limited, we believe in offering the
                      best solution for all your home service needs. We bring
                      together a comprehensive suite of specialized services to
                      ensure your convenience, quality, and peace of mind. Our
                      platform is designed to provide seamless booking, vetted
                      professionals, and reliable service.
                    </p>
                    <p>
                      We understand the challenges of finding trustworthy
                      service providers and maintaining consistent quality.
                      Vimaid solves these issues with a one-stop platform
                      delivering services like cleaning, maintenance, personal
                      care, and health. Our mission is to foster a community of
                      skilled professionals, delivering exceptional quality
                      while upholding our values of Integrity, Customer
                      Satisfaction, Quality, Innovation, and Community.
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>
                            We bring vetted and reliable professionals to your
                            door for every service you need.
                          </li>
                          <li>
                            We ensure consistent quality across all offerings to
                            meet your expectations.
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>
                            Our robust platform enables seamless booking and
                            personalized solutions.
                          </li>
                          <li>
                            We are growing globally, with plans to expand beyond
                            the UK and build a culture of customer satisfaction
                            and excellence.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /About */}
          {/* Work Section */}
          <section className="work-section work-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading">
                    <h2>How It Works</h2>
                    <p>Aliquam lorem ante, dapibus in, viverra quis</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/work-icon.svg"
                          alt="img"
                        />
                      </span>
                    </div>
                    <h5>Select Your Service</h5>
                    <p>
                      Begin by choosing from our extensive list of
                      services—cleaning, maintenance, health, personal care, and
                      more. Our platform offers everything you need for a
                      well-maintained home.!
                    </p>
                    <h4>01</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/find-icon.svg"
                          alt="img"
                        />
                      </span>
                    </div>
                    <h5>Find Your Perfect Match</h5>
                    <p>
                      We will connect you with trusted professionals who meet
                      your needs. Our vetted service providers ensure quality
                      and reliability, so you can book with confidence.!
                    </p>
                    <h4>02</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/place-icon.svg"
                          alt="img"
                        />
                      </span>
                    </div>
                    <h5>Sit Back and Relax</h5>
                    <p>
                      Enjoy exceptional service delivered right to your door.
                      Whether it’s routine cleaning or specialized personal
                      care, our team will make sure your experience is
                      hassle-free and delightful.!
                    </p>
                    <h4>03</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Work Section */}
          {/* Choose Us Section */}
          <div className="chooseus-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="choose-content">
                    <h2>Why Choose Us</h2>
                    <p>
                      At Vimaid Lifetech Limited, we are dedicated to providing
                      a reliable and exceptional service experience, built on
                      the following pillars:
                    </p>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#aboutone"
                          aria-expanded="false"
                        >
                          24/7 Supports
                        </Link>
                      </h4>
                      <div id="aboutone" className="card-collapse collapse">
                        <p>
                          Our dedicated customer support team is available
                          around the clock to address your needs. We are here to
                          ensure that your experience is smooth and hassle-free.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#abouttwo"
                          aria-expanded="false"
                        >
                          Client’s reviews
                        </Link>
                      </h4>
                      <div id="abouttwo" className="card-collapse collapse">
                        <p>
                          Our clients trust us because we deliver quality every
                          time. Their positive feedback and testimonials speak
                          to our commitment to service excellence.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about3"
                          aria-expanded="false"
                        >
                          Professional Team
                        </Link>
                      </h4>
                      <div id="about3" className="card-collapse collapse">
                        <p>
                          Our team of vetted professionals brings expertise and
                          reliability to every service we offer. They uphold the
                          highest standards of quality, giving you peace of
                          mind.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about4"
                          aria-expanded="false"
                        >
                          Best Services
                        </Link>
                      </h4>
                      <div id="about4" className="card-collapse collapse">
                        <p>
                          From cleaning to health and personal care, our
                          expansive suite of services ensures that you get the
                          best in every category. Quality, innovation, and
                          customer satisfaction are at the heart of what we do.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="chooseus-img">
                    <ImageWithBasePath
                      src="assets/img/about-02.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>3500+</h5>
                      <p>Satisfied Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-01.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>400+</h5>
                      <p>Expert Team Members</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.png"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>1200+</h5>
                      <p>Projects Completed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon border-0">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-03.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>10+</h5>
                      <p>Years of Experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Choose Us Section */}
          {/* Providers Section */}
          <section className="providers-section abt-provider">
            <div className="container">
              <div className="section-heading">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Top Providers</h2>
                    <p>Meet Our Experts</p>
                  </div>
                  <div className="col-md-6 text-md-end">
                    <Link
                      to={routes.provider}
                      className="btn btn-primary btn-view"
                    >
                      View All
                      <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-11.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>John Smith</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Electrician</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $20.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(320)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-12.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Michael</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Carpenter</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $50.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(228)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-13.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Antoinette</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Cleaner</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $25.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(130)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-14.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Thompson</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Mechanic</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $25.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(95)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Providers Section */}
          {/* Client Section */}
          <section className="client-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading aos" data-aos="fade-up">
                    <h2>What Our Clients Say</h2>
                    <p>We are trusted for our quality and reliability</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="testimonial-slider">
                    <Slider {...clientSlider}>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Client Avatar"
                              src="assets/img/profiles/avatar-01.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            “Vimaid provided excellent service, meeting our
                            needs and exceeding expectations. We felt valued
                            throughout the process.”
                          </p>
                          <h5>Sophie Moore</h5>
                          <h6>Software Office Manager</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Client Avatar"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            “The team was highly professional, and the booking
                            process was seamless. Vimaid is my go-to for home
                            services now.”
                          </p>
                          <h5>Muhammed Avcı</h5>
                          <h6>Airbnb Home Owner</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Client Avatar"
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            “Thanks to Vimaid, our home services are now
                            streamlined and stress-free. Their professionals are
                            reliable and thorough.”
                          </p>
                          <h5>Furkan Baran</h5>
                          <h6>Chief Executive Officer</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Client Section */}
          {/* Service Section */}
          <div className="service-offer">
            <div className="container">
              <div className="col-md-12">
                <div className="offer-paths about-offer">
                  <div className="offer-path-content">
                    <h3>Looking for the Best Service Finder &amp; Bookings?</h3>
                    <p>
                      Our platform simplifies finding the right service. With Vimaid&apos;s seamless booking process, it&apos;s never been easier to get the reliable home services you need.
                    </p>
                    <Link
                      to={routes.serviceGrid}  //Adjust to your actual routes
                      className="btn btn-primary btn-views"
                    >
                      Get Started
                      <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                  <div className="offer-pathimg">
                    <ImageWithBasePath
                      src="assets/img/service-img.png"  //Ensure this path matches your directory
                      alt="Service Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Service Section */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
