import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';

const TermsCondition = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Terms &amp; Condition</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Terms &amp; Condition
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}
            <div className="col-md-12">
              <div className="terms-content">
                <h2>Terms of Use for Vimaid Lifetech Limited</h2>
                <ol>
                  <li>
                    <strong>Acceptance of Terms</strong>
                    <p>
                      By accessing and using the services provided by Vimaid
                      Lifetech Limited, you agree to comply with and be bound by
                      the following terms and conditions. If you do not agree to
                      these terms, you must not use our services.
                    </p>
                  </li>
                  <li>
                    <strong>Services Description</strong>
                    <p>
                      Vimaid provides a platform for connecting individuals
                      seeking home and specialized services with vetted service
                      providers. Our services are designed to offer convenience,
                      quality, and reliability, facilitating seamless booking
                      and delivery.
                    </p>
                  </li>
                  <li>
                    <strong>User Obligations</strong>
                    <p>
                      Users must provide accurate and current information when
                      registering and using Vimaid&apos;s services. You are
                      responsible for maintaining the confidentiality of your
                      account information and for all activities under your
                      account.
                    </p>
                  </li>
                  <li>
                    <strong>Service Provider Vetting</strong>
                    <p>
                      Vimaid takes steps to ensure the quality and reliability
                      of service providers on our platform. However, we do not
                      guarantee the performance of any service provider and are
                      not liable for any disputes or damages arising from
                      services provided.
                    </p>
                  </li>
                  <li>
                    <strong>Intellectual Property</strong>
                    <p>
                      All content and materials available through our services,
                      including designs, text, graphics, and logos, are owned by
                      Vimaid Lifetech Limited and are protected by copyright and
                      intellectual property laws.
                    </p>
                  </li>
                  <li>
                    <strong>User Conduct</strong>
                    <p>
                      Users are prohibited from using Vimaid&apos;s platform for any
                      unlawful purpose. This includes, but is not limited to,
                      harassment, unauthorized advertising, and infringing on
                      the privacy of others.
                    </p>
                  </li>
                  <li>
                    <strong>Limitation of Liability</strong>
                    <p>
                      Vimaid Lifetech Limited shall not be liable for any
                      direct, indirect, incidental, special, or consequential
                      damages resulting from the use or inability to use our
                      services.
                    </p>
                  </li>
                  <li>
                    <strong>Indemnification</strong>
                    <p>
                      You agree to indemnify and hold Vimaid Lifetech Limited
                      and its affiliates, officers, and employees harmless from
                      any claim or demand made by any third party due to or
                      arising out of your use of our services.
                    </p>
                  </li>
                  <li>
                    <strong>Modification of Terms</strong>
                    <p>
                      Vimaid reserves the right to modify these terms at any
                      time. We will notify users of any changes by updating the
                      terms on our website. Your continued use of our services
                      following any changes constitutes your acceptance of the
                      new terms.
                    </p>
                  </li>
                  <li>
                    <strong>Governing Law</strong>
                    <p>
                      These terms shall be governed by and construed in
                      accordance with the laws of the United Kingdom, without
                      giving effect to any principles of conflicts of law.
                    </p>
                  </li>
                  <li>
                    <strong>Contact Information</strong>
                    <p>
                      For any questions or concerns regarding these terms,
                      please contact us at{' '}
                      <a href="mailto:muhammed@vimaid.com">
                        muhammed@vimaid.com
                      </a>
                      .
                    </p>
                  </li>
                </ol>
                <div className="terms-btn">
                  <Link to="#" className="btn btn-secondary">
                    Not right now...
                  </Link>
                  <Link to="#" className="btn btn-primary">
                    I agree with terms
                  </Link>
                </div>
              </div>
            </div>
            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
