import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { CustomerSidebarItem } from '../../../../core/models/interface';

const CustomerSideBar = () => {
  const routes = all_routes;
  const location = useLocation();
  const sidebar_data = [
    {
      link: routes.customerDashboard,
      name: 'Dashboard',
      icon: <Icon.Grid className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerBooking,
      name: 'Bookings',
      icon: <Icon.Smartphone className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerFavourite,
      name: 'Favorites',
      icon: <Icon.Heart className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerWallet,
      name: 'Wallet',
      icon: <Icon.CreditCard className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerReviews,
      name: 'Reviews',
      icon: <Icon.Star className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerChat,
      name: 'Chat',
      icon: <Icon.MessageCircle className="sidebar-feather me-3" />,
    },
    {
      link: routes.customerBooking,
      name: 'Settings',
      icon: <Icon.Settings className="sidebar-feather me-3" />,
    },
    {
      link: routes.homeOne,
      name: 'LogOut',
      icon: <Icon.LogOut className="sidebar-feather me-3" />,
    },
  ];

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  return (
    <>
      <div className="col-lg-3  theiaStickySidebar">
        <StickyBox>
          {/* Customer Menu */}
          <div className="settings-widget">
            <div className="settings-header">
              <div className="settings-img">
                <ImageWithBasePath
                  src="assets/img/profiles/avatar-02.jpg"
                  alt="user"
                />
              </div>
              <h6>John Smith</h6>
              <p>Member Since Sep 2021</p>
            </div>
            <div className="settings-menu">
              <ul>
                {sidebar_data.map(
                  (item: CustomerSidebarItem, index: number) => {
                    return (
                      <>
                        <li key={index}>
                          <Link
                            className={`${
                              activeRouterPath(item.link) ? 'active' : ''
                            }`}
                            to={item.link}
                          >
                            {item.icon} {<span>{item.name}</span>}{' '}
                          </Link>
                        </li>
                      </>
                    );
                  },
                )}
              </ul>
            </div>
          </div>
        </StickyBox>
        {/* /Customer Menu */}
      </div>
    </>
  );
};

export default CustomerSideBar;
