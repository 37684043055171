import React from 'react'
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ProviderSecuritySettings = () => {
  const routes = all_routes;
  return (
    <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">	
            <div className="col-md-12">	
              {/* Security settings */}
              <div className="widget-title">
                <h4>Security Settings</h4>
              </div>
              <div className="linked-item">
                <div className="linked-wrap">
                  <div className="linked-acc">
                    <span className="link-icon">
                      <ImageWithBasePath src="assets/img/icons/lock-icon.svg" alt="Lock" />
                    </span>
                    <div className="linked-info">
                      <h6>Password</h6>
                      <p>Last Changed  <span>22 Sep 2023, 10:30:55 AM</span></p>
                    </div>
                  </div>
                  <div className="linked-action">
                    <button className="btn btn-secondary btn-set" data-bs-toggle="modal" data-bs-target="#change-password">Change Password</button>
                  </div>
                </div>
              </div>
              <div className="linked-item">
                <div className="row">
                  <div className="col-md-8">
                    <div className="linked-acc">
                      <span className="link-icon">
                        <ImageWithBasePath src="assets/img/icons/scan-icon.svg" alt="Search" />
                      </span>
                      <div className="linked-info">
                        <h6>Two Factor Authendication <span className="badge badge-success">Enable</span></h6>
                        <p>Lorem ipsum dolor sit amet, consectetur </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-md-end">
                    <div className="d-flex align-items-center justify-content-md-end">
                      <Link to="#" className="btn btn-secondary btn-set">Disable</Link>
                      <div className="status-toggle status-tog d-inline-flex justify-content-between align-items-center">
                        <input type="checkbox" id="status_1" className="check" defaultChecked />
                        <label htmlFor="status_1" className="checktoggle">checkbox</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="linked-item">
                <div className="linked-wrap">
                  <div className="linked-acc">
                    <span className="link-icon">
                      <ImageWithBasePath src="assets/img/icons/monitor-icon.svg" alt="image" />
                    </span>
                    <div className="linked-info">
                      <h6>Device Management</h6>
                      <p>Last Changed  <span>22 Sep 2023, 10:30:55 AM</span></p>
                    </div>
                  </div>
                  <div className="linked-action">
                    <Link to={routes. providerDeviceManagement} className="btn btn-secondary btn-set">Manage</Link>
                  </div>
                </div>
              </div>
              <div className="linked-item">
                <div className="linked-wrap">
                  <div className="linked-acc">
                    <span className="link-icon">
                      <ImageWithBasePath src="assets/img/icons/user-edit.svg" alt="image" />
                    </span>
                    <div className="linked-info">
                      <h6>Account Activity</h6>
                      <p>Last login  <span>22 Sep 2023, 10:21:55 AM</span></p>
                    </div>
                  </div>
                  <div className="linked-action">
                    <Link to={routes.providerLoginActivity} className="btn btn-secondary btn-set">View All</Link>
                  </div>
                </div>
              </div>
            </div>
            {/* /Security-settings */}
          </div>
        </div>
         {/* Change Password  */}
         <div className="modal fade custom-modal" id="change-password">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content doctor-profile">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Change Password</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /><Icon.X className="react-feather-icon" /></button>
              </div>
              <div className="modal-body pt-0">
                <form action={routes.success}>
                  <div className="form-group">
                    <label className="col-form-label">Current Password</label>
                    <div className="pass-group">
                      <input type="password" className="form-control pass-input" placeholder="*************" />
                      <span className="toggle-password feather-eye-off" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">New Password</label>
                    <div className="pass-group" id="passwordInput">
                      <input type="password" className="form-control pass-input" placeholder="*************" />
                      {/* <span className="toggle-password feather-eye-off" /> */}
                      <Icon.EyeOff className="toggle-password react-feather-icon" />
                    </div>
                    <div className="password-strength" id="passwordStrength">
                      <span id="poor" />
                      <span id="weak" />
                      <span id="strong" />
                      <span id="heavy" />
                    </div>
                    <div id="passwordInfo">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Confirm New Password</label>
                    <div className="pass-group">
                      <input type="password" className="form-control pass-input" placeholder="*************" />
                      {/* <span className="toggle-password feather-eye-off" /> */}
                      <Icon.EyeOff className="toggle-password react-feather-icon" />
                    </div>
                  </div>
                  <div className="acc-submit">
                    <Link to="#" className="btn btn-secondary">Cancel</Link>
                    <button className="btn btn-primary" type="submit">Update Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Change Phone  */}
        {/* Change Email */}
        <div className="modal fade custom-modal" id="change-email">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content doctor-profile">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Change Email</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /><Icon.X className="react-feather-icon" /></button>
              </div>
              <div className="modal-body pt-0">
                <form>
                  <div className="wallet-add">
                    <div className="form-group">
                      <label className="col-form-label">Current Email Address</label>
                      <input type="email" className="form-control" placeholder="Enter Email Address" />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">New Email Address <span className="text-danger">*</span></label>
                      <input type="email" className="form-control" placeholder="Enter Email Address" />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Confirm New Password</label>
                      <div className="pass-group">
                        <input type="password" className="form-control pass-input" placeholder="*************" />
                        {/* <span className="toggle-password feather-eye-off" /> */}
                        <Icon.EyeOff className="toggle-password react-feather-icon" />
                      </div>
                      <p className="brief-bio mb-0 mt-2"><i className="feather-alert-circle" /><Icon.AlertCircle className="react-feather-icon" /> New Email Address Only Updated Once You Verified</p>
                    </div>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button className="btn btn-primary">Change Email</button>
                    </div>								
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Change Email */}
        {/* Change Phone  */}
        <div className="modal fade custom-modal" id="change-phone">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content doctor-profile">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Change Phone Number</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /><Icon.X className=" react-feather-icon" /></button>
              </div>
              <div className="modal-body pt-0">
                <form>
                  <div className="wallet-add">
                    <div className="form-group">
                      <label className="col-form-label">Current Phone Number</label>
                      <input className="form-control form-control-lg group_formcontrol" id="phone" name="phone" type="text" />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">New Phone Number <span className="text-danger">*</span></label>
                      <input className="form-control form-control-lg group_formcontrol" id="phone1" name="phone" type="text" />
                      <p className="brief-bio mb-0 mt-2"><i className="feather-alert-circle" /> New Phone Number Only Updated Once You Verified</p>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Confirm New Password</label>
                      <div className="pass-group">
                        <input type="password" className="form-control pass-input" placeholder="*************" />
                        <Icon.EyeOff className="toggle-password react-feather-icon" />
                      </div>
                    </div>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button className="btn btn-primary">Change Number</button>
                    </div>								
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Change Phone  */}
      </div>

  )
}

export default ProviderSecuritySettings