import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Categories = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Categories</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Category List */}
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-06.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-01.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Computer</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-09.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-02.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Cleaning</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-01.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-03.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Electrical</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-10.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-04.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Construction</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-15.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-05.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Interior</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-08.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-06.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Car Wash</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-11.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-07.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Plumbing</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-03.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-08.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Carpentry</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 d-flex">
              <div className="category-card flex-fill">
                <div className="category-img">
                  <Link to={routes.search}>
                    <ImageWithBasePath
                      src="assets/img/services/service-16.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                  </Link>
                </div>
                <div className="category-info">
                  <div className="category-name">
                    <span className="category-icon">
                      <ImageWithBasePath
                        src="assets/img/icons/category-09.svg"
                        alt="Service"
                      />
                    </span>
                    <h6>
                      <Link to={routes.search}>Appliance</Link>
                    </h6>
                  </div>
                  <p>22 Services</p>
                </div>
              </div>
            </div>
            {/* /Category List */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
