import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Faq = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Frequently Asked Questions</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Frequently Asked Questions
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* FAQ List */}
            <div className="col-md-10 mx-auto">
              <div className="faq-section">
                {/* FAQ Card 1 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq1"
                      aria-controls="faq1"
                      aria-expanded="false"
                    >
                      What services does Vimaid offer?
                    </Link>
                  </h4>
                  <div id="faq1" className="card-collapse collapse">
                    <p>
                      Vimaid offers a wide range of home services, including
                      cleaning, maintenance, personal care, and health services,
                      through vetted professionals.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 2 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq2"
                      aria-controls="faq2"
                      aria-expanded="false"
                    >
                      How do I book a service with Vimaid?
                    </Link>
                  </h4>
                  <div id="faq2" className="card-collapse collapse">
                    <p>
                      You can book a service via our mobile app or website.
                      Simply select the service, choose a time, and confirm your
                      booking.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 3 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq3"
                      aria-controls="faq3"
                      aria-expanded="false"
                    >
                      What regions does Vimaid cover?
                    </Link>
                  </h4>
                  <div id="faq3" className="card-collapse collapse">
                    <p>
                      Vimaid currently serves major cities across Turkey and is
                      expanding globally. Check our website to see if we operate
                      in your area.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 4 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq4"
                      aria-controls="faq4"
                      aria-expanded="false"
                    >
                      Are Vimaid professionals background-checked?
                    </Link>
                  </h4>
                  <div id="faq4" className="card-collapse collapse">
                    <p>
                      Yes, all Vimaid professionals undergo thorough vetting and
                      background checks to ensure reliability and safety.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 5 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq5"
                      aria-controls="faq5"
                      aria-expanded="false"
                    >
                      Can I reschedule or cancel my booking?
                    </Link>
                  </h4>
                  <div id="faq5" className="card-collapse collapse">
                    <p>
                      Yes, you can reschedule or cancel a booking up to 24 hours
                      before the scheduled service time without any extra
                      charges.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 6 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq6"
                      aria-controls="faq6"
                      aria-expanded="false"
                    >
                      How do I become a service provider with Vimaid?
                    </Link>
                  </h4>
                  <div id="faq6" className="card-collapse collapse">
                    <p>
                      If you&apos;re interested in joining Vimaid as a
                      professional, visit our &quot;Become a Professional&quot;
                      page, fill out the form, and our team will guide you
                      through the process.
                    </p>
                  </div>
                </div>
                {/* FAQ Card 7 */}
                <div className="faq-card">
                  <h4 className="faq-title">
                    <Link
                      className="collapsed"
                      data-bs-toggle="collapse"
                      to="#faq7"
                      aria-controls="faq7"
                      aria-expanded="false"
                    >
                      How does Vimaid ensure service quality?
                    </Link>
                  </h4>
                  <div id="faq7" className="card-collapse collapse">
                    <p>
                      We regularly review feedback from customers and monitor
                      service performance. We also provide training and support
                      to our professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* /FAQ List */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
