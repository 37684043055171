import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Notification = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const routes = all_routes;
  return (
    <>
      <>
  {/* Breadcrumb */}
  <div className="breadcrumb-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="breadcrumb-title">Notifications</h2>
          <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={routes.homeOne}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Notifications
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  {/* /Breadcrumb */}
  <div className="content-notify content">
    <div className="container">
      {/* Contact Details */}
      <div className="notification-details notify-content-swap">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="notification-wrap-date d-flex align-items-center justify-content-between">
              <div className="search-input search-calendar-line">
                <Icon.Calendar style={{ marginRight: '2px' }}/>
                <div className=" mb-0">
                <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}               
                placeholderText='09-04-2023'
                className="form-control floating datetimepicker"
              />
                </div>
              </div>
              <div className="noti-read-delete d-flex align-items-center">
                <div className="notification-read">
                  <Link to="#" className="mark-all">
                    {" "}
                    <Icon.Check className="react-feather-custom" style={{ marginRight: '2px' }}/> Mark all as read
                 </Link>
                </div>
                <div className="notification-delete-content">
                  <Link to="#">
                   <Icon.Trash2 className="react-feather-custom" style={{ marginRight: '2px' }}/>
                    Delete all
                 </Link>
                </div>
              </div>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                <li className="notification-message">
                  <div className="notification-delete-wrap d-flex align-items-center justify-content-between">
                    <div className="media noti-img d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <ImageWithBasePath
                          className="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="assets/img/notifications/avatar-01.jpg"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <Link to="#">
                          <p className="noti-details">
                            Lex Murphy left 6 comments on Isla Nublar SOC2
                            compliance report
                          </p>
                       </Link>
                        <p className="noti-time">
                          <span className="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                    <div className="notification-delete d-flex align-items-center">
                      <div className="notification-dropdown">
                        <Link
                          to="#"
                          className="d-flex"
                          data-bs-toggle="dropdown"
                        >
                          <Icon.MoreVertical/>
                       </Link>
                        <div className="dropdown-menu">
                          <Link to="#" className="dropdown-item">
                            <i className="far fa-check-circle me-1" /> Mark as
                            read
                         </Link>
                        </div>
                      </div>
                      <Link to="#" className="btn-acc">
                        Delete
                     </Link>
                    </div>
                  </div>
                </li>
                <li className="notification-message">
                  <div className="notification-delete-wrap d-flex align-items-center justify-content-between">
                    <div className="media noti-img d-flex">
                      <Link to={routes.commonNotification}>
                        <span className="avatar avatar-sm flex-shrink-0">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="assets/img/notifications/avatar-02.jpg"
                          />
                        </span>
                     </Link>
                      <div className="media-body flex-grow-1">
                        <Link to="#">
                          <p className="noti-details">
                            Ray Arnold requested access to UNIX directory tree
                            hierarchy
                          </p>
                       </Link>
                        <p className="noti-time">
                          <span className="notification-time">1 min ago</span>
                        </p>
                        <div className="notify-btns">
                          <button className="btn btn-secondary">Decline</button>
                          <button className="btn btn-primary">Accept</button>
                        </div>
                      </div>
                    </div>
                    <div className="notification-delete d-flex align-items-center">
                      <div className="notification-dropdown">
                        <Link
                          to="#"
                          className="d-flex"
                          data-bs-toggle="dropdown"
                        >
                          <Icon.MoreVertical/>
                       </Link>
                        <div className="dropdown-menu">
                          <Link to="#" className="dropdown-item">
                            <i className="far fa-check-circle me-1" /> Mark as
                            read
                         </Link>
                        </div>
                      </div>
                      <Link to="#" className="btn-acc">
                        Delete
                     </Link>
                    </div>
                  </div>
                </li>
                <li className="notification-message">
                  <div className="notification-delete-wrap d-flex align-items-center justify-content-between">
                    <div className="media noti-img d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <ImageWithBasePath
                          className="avatar-img rounded-circle img-fluid"
                          alt="User Image"
                          src="assets/img/notifications/avatar-04.jpg"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <Link to="#">
                          <p className="noti-details">
                            John Hammond created Isla Nublar SOC2 compliance
                            report
                          </p>
                       </Link>
                        <p className="noti-time">
                          <span className="notification-time">1m ago</span>
                        </p>
                      </div>
                    </div>
                    <div className="notification-delete d-flex align-items-center">
                      <div className="notification-dropdown">
                        <Link
                          to="#"
                          className="d-flex"
                          data-bs-toggle="dropdown"
                        >
                         <Icon.MoreVertical/>
                       </Link>
                        <div className="dropdown-menu">
                          <Link to="#" className="dropdown-item">
                            <i className="far fa-check-circle me-1" /> Mark as
                            read
                         </Link>
                        </div>
                      </div>
                      <Link to="#" className="btn-acc">
                        Delete
                     </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* /Contact Details */}
    </div>
  </div>
</>

    </>
  )
}

export default  Notification
