import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DatePicker from 'react-datepicker';

// import * as Icon from 'react-feather'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import FooterThree from './footer-three';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Dropdown } from 'primereact/dropdown';
import HomeHeader from '../header/home-header';

const HomeThree = () => {
  const reviews = [
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras.Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'John Doe',
      country: 'USA',
      img: 'assets/img/profiles/avatar-04.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras.Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Paul Walker',
      country: 'Newyork, USA',
      img: 'assets/img/profiles/avatar-01.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras.Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Anthony Walker',
      country: 'Newyork, USA',
      img: 'assets/img/profiles/avatar-02.jpg',
    },
    {
      review:
        '“ Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras.Vitae amet cras nulla mi laoreet quis amet phasellus. Enim orci lacus quam mauris nunc ultrices duis. Ornare leo mi aenean egestas montes cras. Egestas erat viverra scelerisque bibendum. “',
      name: 'Van Diesel',
      country: 'Newyork, USA',
      img: 'assets/img/profiles/avatar-03.jpg',
    },

    // Add more review objects as needed
  ];
  if (!reviews || !Array.isArray(reviews)) {
    // Handle the case where reviews is not present or not an array
    return null; // or return a default component or message
  }
  const [slider1, setSlider1] = useState();
  const [slider2, setSlider2] = useState();

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };
  const One = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };
  const Two = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  const slideConfig = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nav: false,
  };

  const [selectedSub, setselectedSub] = useState(null);
  const category = [
    { name: 'Select Service type' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
  const handleSlider1Change = (index: any) => {
     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    slider2.slickGoTo(index);
  };

  const handleSlider2Change = (index: any) => {
     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     // @ts-expect-error
    slider1.slickGoTo(index);
    
  };
  const slideConfig2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show three images in parallel
    slidesToScroll: 1,
    centerMode: true,
  };
  return (
    <>
      <HomeHeader type={3} />
      {/* Banner Section */}
      <section className="hero-section-three">
        <div className="container">
          <div className="home-banner home-banner-three aos" data-aos="fade-up">
            <div className="row align-items-center w-100">
              <div className="col-lg-6 col-md-12 mx-auto">
                <div className="section-search section-section-three">
                  <h4>Best Saloon Template</h4>
                  <h1>Find the Awesome Saloon’s Near you</h1>
                  <p>
                    Hair and SPA Salons and fully integrated with theme tools
                    that you can use for the promotion of your business.
                  </p>
                  <Link to={''} className="make-appointment">
                    MAKE AN APPOINTMENT
                  </Link>
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="search-box search-box-three aos" data-aos="fade-up">
            <form action={''} className="d-flex search-form-profile">
              <div className="searchbox-list">
                <div className="search-input search-input-three">
                  <Icon.CheckSquare className="react-feather-custom" />
                  <div className="form-group m-0">
                    <Dropdown
                      value={selectedSub}
                      onChange={(e) => setselectedSub(e.value)}
                      options={category}
                      optionLabel="name"
                      placeholder="Job Title"
                      className="select select border-none"
                    />
                  </div>
                </div>
                <div className="search-input search-input-three">
                  <Icon.MapPin className="react-feather-custom me-1" />
                  <div className="form-group mb-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Location"
                    />
                  </div>
                </div>
                <div className="search-input search-input-three">
                  <Icon.Calendar className="react-feather-custom" />
                  <div className="form-group mb-0">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
                      type="date"
                      placeholderText="Select Date"
                      className="form-control floating datetimepicker"
                    />
                  </div>
                </div>
              </div>
              <div className="search-btn search-btn-three">
                <button className="btn btn-primary" type="submit">
                  <Icon.Search className="react-feather-custom" />
                  SEARCH HERE
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* /Banner Section */}
      {/* Services Section */}
      <section className="services-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Our Services</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings} className="services-slider">
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/services/service-20.png"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/saloon-service-2.svg"
                        alt="Saloon"
                      />
                      <h4>Haircut</h4>
                      <h6>25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/services/service-21.png"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/saloon-service-2.svg"
                        alt="Saloon"
                      />
                      <h4>Trimming</h4>
                      <h6>25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/services/service-22.png"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/saloon-service-2.svg"
                        alt="Saloon"
                      />
                      <h4>Saloons</h4>
                      <h6>25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/services/service-23.png"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/saloon-service-2.svg"
                        alt="Saloon"
                      />
                      <h4>Shaving</h4>
                      <h6>25 Saloons</h6>
                    </div>
                  </div>
                </div>
                <div className="services-all">
                  <div className="services-main-img">
                    <Link to="">
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/services/service-20.png"
                      />
                    </Link>
                    <div className="service-foot">
                      <ImageWithBasePath
                        src="assets/img/icons/saloon-service-2.svg"
                        alt="Saloon"
                      />
                      <h4>Haircut</h4>
                      <h6>25 Saloons</h6>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="sidecircle-ryt">
          <ImageWithBasePath src="assets/img/side-circle.png" alt="Circle" />
        </div>
      </section>
      {/* /service Section */}
      {/* Service Section */}
      <section className="service-section featured-saloons">
        <div className="saloon-section-circle">
          <ImageWithBasePath src="assets/img/side-circle.png" alt="Circle" />
        </div>
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Featured Saloons</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="service-slider aos" data-aos="fade-right">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-24.jpg"
                      />
                    </Link>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to={''}>The Rockstar Barber</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-20.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <span>
                              <i className="feather-map-pin" />
                            </span>
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to={''}>
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="service-slider aos" data-aos="fade-up">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-25.jpg"
                      />
                    </Link>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to={''}>Femina Hairstyle</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-19.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <i className="feather-map-pin" />
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to={''}>
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="service-slider aos" data-aos="fade-left">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-26.jpg"
                      />
                    </Link>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to={''}>The Macho Lever</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-18.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <i className="feather-map-pin" />
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to={''}>
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="service-slider  aos" data-aos="fade-right">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-27.jpg"
                      />
                    </Link>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to={''}>Master Barber</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-17.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <i className="feather-map-pin" />
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to="">
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 col-12">
              <div className="service-slider  aos" data-aos="fade-up">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <div className="service-img-top">
                      <Link to={''}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service"
                          src="assets/img/services/service-28.jpg"
                        />
                      </Link>
                    </div>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to={''}>Rearhair Stylist</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-15.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <i className="feather-map-pin" />
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to={''}>
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 col-12 ">
              <div className="service-slider aos" data-aos="fade-left">
                <div className="service-widget">
                  <div className="service-img service-show-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-24.jpg"
                      />
                    </Link>
                    <div className="item-info item-infos">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                      </div>
                    </div>
                  </div>
                  <div className="service-content service-content-three">
                    <h3 className="title">
                      <Link to="#">The Rockstar Barber</Link>
                    </h3>
                    <ul>
                      <li>Hair Cut</li>
                      <li>Hair Styling</li>
                      <li>Clean Shaving</li>
                    </ul>
                    <div className="main-saloons-profile">
                      <div className="saloon-profile-left">
                        <div className="saloon-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-20.jpg"
                            alt="User"
                          />
                        </div>
                        <div className="saloon-content">
                          <div className="saloon-content-top">
                            <i className="feather-clock" />
                            <span>07:00 AM - 11:00 PM </span>
                          </div>
                          <div className="saloon-content-btn">
                            <i className="feather-map-pin" />
                            <span>Main Boulevard, Lahore,</span>
                          </div>
                        </div>
                      </div>
                      <div className="saloon-right">
                        <span>$70</span>
                      </div>
                    </div>
                    <div className="saloon-bottom">
                      <Link to="">
                        <i className="feather-calendar me-2" />
                        MAKE AN APPOINTMENT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-sec btn-saloons aos" data-aos="fade-up">
              <Link to={''} className="btn btn-primary btn-view">
                VIEW ALL 590 SALOONS
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Service Section */}
      {/* Appointment Section */}
      <section className="appointment-section aos" data-aos="fade-up">
        <div className="container">
          <div className="appointment-main">
            <h6>GET A MODERN LOOK</h6>
            <h2>Upto 25% offer on First Appointment</h2>
            <p>
              No one you see is smarter than he so join us here are sure to get
              a smile from seven stranded.
            </p>
            <div className="appointment-btn">
              <Link to={''} className="btn btn-primary">
                BOOK AN APPOINTMENT NOW
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Appointment Section */}
      {/* Services Section */}
      <section className="services-section stylists-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Featured Stylists</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...One} className="stylists-slider aos">
                <div className="stylists-all">
                  <div className="stylists-main-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/profiles/avatar-04.jpg"
                      />
                    </Link>
                  </div>
                  <div className="stylists-bottom">
                    <div className="stylists-foot">
                      <Link to={''}>
                        <h4>Evelyn Nelson</h4>
                      </Link>
                      <h6>300 Bookings</h6>
                      <div className="stylists-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all">
                  <div className="stylists-main-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/profiles/avatar-05.jpg"
                      />
                    </Link>
                  </div>
                  <div className="stylists-bottom">
                    <div className="stylists-foot">
                      <Link to={''}>
                        <h4>Matthew Joe</h4>
                      </Link>
                      <h6>356 Bookings</h6>
                      <div className="stylists-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all">
                  <div className="stylists-main-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/profiles/avatar-06.jpg"
                      />
                    </Link>
                  </div>
                  <div className="stylists-bottom">
                    <div className="stylists-foot">
                      <Link to={''}>
                        <h4>Matthew Joe</h4>
                      </Link>
                      <h6>145 Bookings</h6>
                      <div className="stylists-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all">
                  <div className="stylists-main-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/profiles/avatar-07.jpg"
                      />
                    </Link>
                  </div>
                  <div className="stylists-bottom">
                    <div className="stylists-foot">
                      <Link to={''}>
                        <h4>Matthew Joe</h4>
                      </Link>
                      <h6>256 Bookings</h6>
                      <div className="stylists-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stylists-all">
                  <div className="stylists-main-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-image"
                        alt="Service"
                        src="assets/img/profiles/avatar-08.jpg"
                      />
                    </Link>
                  </div>
                  <div className="stylists-bottom">
                    <div className="stylists-foot">
                      <Link to={''}>
                        <h4>James George</h4>
                      </Link>
                      <h6>300 Bookings</h6>
                      <div className="stylists-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /service Section */}
      {/* Service Section */}
      <section className="service-section populars-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Popular Locations</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row aos" data-aos="fade-up">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-30.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>USA</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-31.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>UK</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-32.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>Mexico</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-33.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>UAE</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-34.png"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>France</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-35.png"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>Germany</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-36.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>Italy</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div className="service-slider">
                <div className="service-widget">
                  <div className="service-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-37.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content popular-content">
                    <Link to={''}>
                      <h3>Argentina</h3>
                    </Link>
                    <h6>49 Saloons</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-sec btn-saloons aos" data-aos="fade-up">
              <Link to={''} className="btn btn-primary btn-view">
                VIEW ALL 590 LOCATION
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Service Section */}
      {/* Works Section */}
      <section className="works-section">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>How It Works</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-right">
                <div className="works-tops">
                  <div className="works-top-img">
                    <ImageWithBasePath
                      src="assets/img/services/service-30.jpg"
                      alt="Service"
                    />
                    <span>1</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to={''}>
                    <h4>Discover</h4>
                  </Link>
                  <p>
                    Barber is a person whose occupation is mainly to cut dress
                    groom style and shave men.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-up">
                <div className="works-tops">
                  <div className="works-top-img works-load-profile">
                    <ImageWithBasePath
                      src="assets/img/services/service-25.jpg"
                      alt="Service"
                    />
                    <span>2</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to={''}>
                    <h4>Basics</h4>
                  </Link>
                  <p>
                    Barber is a person whose occupation is mainly to cut dress
                    groom style and shave men.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div className="works-main aos" data-aos="fade-left">
                <div className="works-tops">
                  <div className="works-top-img">
                    <ImageWithBasePath
                      src="assets/img/services/service-30.jpg"
                      alt="Service"
                    />
                    <span>3</span>
                  </div>
                </div>
                <div className="works-bottom">
                  <Link to={''}>
                    <h4>Enjoy</h4>
                  </Link>
                  <p>
                    Barber is a person whose occupation is mainly to cut dress
                    groom style and shave men.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Works Section */}
      {/* client section */}
      <section className="client-sections review-four">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content section-client">
                  <h2>What Our Client Says</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor-white.svg"
                      alt="Service"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Slider
              {...slideConfig}
              className="slider say-about slider-for aos"
              afterChange={handleSlider1Change}
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
              ref={(slider) => setSlider1(slider)}
            >
              {reviews.map((data, index) => (
                <div key={index}>
                  <div className="review-love-group">
                    <div className="quote-love-img">
                      <img
                        className="img-fluid"
                        src="assets/img/icons/quote.svg"
                        alt=""
                      />
                    </div>
                    <p className="review-passage">“ {data.review} “</p>
                    <div className="say-name-blk text-center">
                      <h5>{data.name}</h5>
                      <p>{data.country}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <Slider
              {...slideConfig2}
              className="slider client-img slider-nav client-pro aos"
              afterChange={handleSlider2Change}
               // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
              ref={(slider) => setSlider2(slider)}
            >
              {reviews.slice(0, 3).map((data, index) => (
                <div key={index} className="testimonial-thumb">
                  <img src={data.img} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      {/* /client section */}
      {/* latest section */}
      <section className="services-section latest-section blog-section-three">
        <div className="container">
          <div className="services-header aos" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <div className="section-content">
                  <h2>Our Latest News</h2>
                  <div className="our-img-all">
                    <ImageWithBasePath
                      src="assets/img/icons/scissor.svg"
                      alt="Scissor"
                    />
                  </div>
                  <p>
                    Our Barbershop &amp; Tattoo Salon provides classic services
                    combined with innovative techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...Two} className="latest-slider aos">
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-20.png"
                        alt="Post"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={''}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Link>
                    </h3>
                    <div className="blog-view">
                      <Link to="#">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-21.png"
                        alt="Post"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={''}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Link>
                    </h3>
                    <div className="blog-view">
                      <Link to="#">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-22.png"
                        alt="Post"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={''}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Link>
                    </h3>
                    <div className="blog-view">
                      <Link to="#">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-23.png"
                        alt="Post"
                      />
                    </Link>
                    <div className="date">
                      15<span>Nov 2023</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="blog-category">
                      <ul>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Hair Cut</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <span className="cat-blog">Facial</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h3 className="blog-title">
                      <Link to={''}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Link>
                    </h3>
                    <div className="blog-view">
                      <Link to="#">Read More</Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget">
                  <div className="service-img service-latest-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-20.png"
                      />
                    </Link>
                    <div className="latest-date">
                      <span>15</span>
                      Nov,2023
                    </div>
                  </div>
                  <div className="service-content latest-content">
                    <span>Hair Style</span>
                    <Link to={''} className="latest-news-content">
                      Consectetur adipisicing elit, sed do eiusmod
                    </Link>
                    <Link to="#" className="latest-news">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="service-widget">
                  <div className="service-img service-latest-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-21.png"
                      />
                    </Link>
                    <div className="latest-date">
                      <span>15</span>
                      Nov,2023
                    </div>
                  </div>
                  <div className="service-content latest-content">
                    <span>Hair Style</span>
                    <Link to={''} className="latest-news-content">
                      Consectetur adipisicing elit, sed do eiusmod
                    </Link>
                    <Link to="#" className="latest-news">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="service-widget">
                  <div className="service-img service-latest-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-22.png"
                      />
                    </Link>
                    <div className="latest-date">
                      <span>15</span>
                      Nov,2023
                    </div>
                  </div>
                  <div className="service-content latest-content">
                    <span>Hair Style</span>
                    <Link to={''} className="latest-news-content">
                      Consectetur adipisicing elit, sed do eiusmod
                    </Link>
                    <Link to="#" className="latest-news">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="service-widget">
                  <div className="service-img service-latest-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-23.png"
                      />
                    </Link>
                    <div className="latest-date">
                      <span>15</span>
                      Nov,2023
                    </div>
                  </div>
                  <div className="service-content latest-content">
                    <span>Hair Style</span>
                    <Link to={''} className="latest-news-content">
                      Consectetur adipisicing elit, sed do eiusmod
                    </Link>
                    <Link to="#" className="latest-news">
                      Read More
                    </Link>
                  </div>
                </div>
                <div className="service-widget">
                  <div className="service-img service-latest-img">
                    <Link to={''}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service"
                        src="assets/img/services/service-22.png"
                      />
                    </Link>
                    <div className="latest-date">
                      <span>15</span>
                      Nov,2023
                    </div>
                  </div>
                  <div className="service-content latest-content">
                    <span>Hair Style</span>
                    <Link to={''} className="latest-news-content">
                      Consectetur adipisicing elit, sed do eiusmod
                    </Link>
                    <Link to="#" className="latest-news">
                      Read More
                    </Link>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /latest section */}
      {/* register section */}
      <section className="register-section aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="register-content">
                <h2>Get Registered and List your Saloon for free!!!</h2>
                <div className="register-btn">
                  <Link to={''}>
                    <Icon.Users className="feather-users me-2" size={15} />
                    Register /
                  </Link>
                  <Link to={''}>Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/register section  */}
      <FooterThree />
    </>
  );
};

export default HomeThree;
