import React from 'react'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ServiceDetails2 = () => {
  const routes = all_routes;
  return (
    <>
      <>
  {/* Breadcrumb */}
  <div className="breadcrumb-bar">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-12">
          <h2 className="breadcrumb-title">Service Details 2</h2>
          <nav aria-label="breadcrumb" className="page-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={routes.homeOne}>Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Service Details 2
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  {/* /Breadcrumb */}
  <div className="content section-booking">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="service-view">
            <div className="service-header">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="electric-header">
                  Electrical Panel Repairing Service
                </h1>
              </div>
              <address className="service-location">
                <i className="fas fa-location-arrow" /> Hanover, Maryland
              </address>
              <div className="rating">
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <i className="fas fa-star" />
                <span className="d-inline-block average-rating">(5)</span>
              </div>
              <div className="service-cate d-flex align-items-center">
                <Link to={routes. search}>Electrical</Link>
                <span className="ms-2">
                  <i className="fas fa-heart" />
                </span>
              </div>
            </div>
            <div className="service-images">
              <div className="item">
                <ImageWithBasePath
                  src="assets/img/services/service-033.jpg"
                  alt="image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="service-details">
              <ul
                className="nav nav-pills service-tabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    to="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Overview
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    to="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Services Offered
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    id="pills-book-tab"
                    data-bs-toggle="pill"
                    to="#pills-book"
                    role="tab"
                    aria-controls="pills-book"
                    aria-selected="false"
                  >
                    Reviews
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane fade"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="card service-description">
                    <div className="card-body">
                      <h5 className="card-title">Service Details</h5>
                      <p className="mb-0">
                        Car wash is a facility used to clean the exterior and,
                        in some cases, the interior of motor vehicles. Car
                        washes can be self-serve, fully automated, or
                        full-service with attendants who wash the vehicle.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Services Offered</h5>
                      <div className="serviced-offer">
                        <ul className="list-bullet">
                          <li>
                            {" "}
                            <span>
                              {" "}
                              <i className="fa-regular fa-circle-check" />{" "}
                            </span>{" "}
                            Changing Switch Boards
                          </li>
                          <li>
                            {" "}
                            <span>
                              {" "}
                              <i className="fa-regular fa-circle-check" />{" "}
                            </span>{" "}
                            Swapping of Lines &amp; Circuits
                          </li>
                          <li>
                            {" "}
                            <span>
                              {" "}
                              <i className="fa-regular fa-circle-check" />{" "}
                            </span>{" "}
                            Main Board Switches alinged
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-book"
                  role="tabpanel"
                  aria-labelledby="pills-book-tab"
                >
                  <div className="card review-box">
                    <div className="card-body p-0">
                      <span>No reviews found</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 className="card-title">Related Services</h4>
          <div className="service-carousel">
            <div className="popular-slider">
              <div className="row">
                <div className="col-md-6">
                  <div className="service-widget">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-18.jpg"
                        />
                      </Link>
                      <div className="item-info">
                        <div className="service-user">
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/provider/provider-01.jpg"
                              alt="image"
                            />
                          </Link>
                          <span className="service-price">$50</span>
                        </div>
                        <div className="cate-list">
                          <Link className="bg-yellow" to={routes.serviceDetails1}>
                            Car Wash
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>Car Repair Services</Link>
                      </h3>
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star" />
                        <span className="d-inline-block average-rating">
                          (4.5)
                        </span>
                      </div>
                      <div className="user-info d-flex justify-content-between align-items-center ms-0">
                        <h6 className="ser-contact">
                          <i className="fas fa-phone me-2" />{" "}
                          <span>xxxxxxxx12</span>
                        </h6>
                        <h6 className="ser-location">
                          <span>Maryland City, ...</span>{" "}
                          <i className="fas fa-map-marker-alt ms-1" />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="service-widget">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-17.jpg"
                        />
                      </Link>
                      <div className="item-info">
                        <div className="service-user">
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/provider/provider-01.jpg"
                              alt="image"
                            />
                          </Link>
                          <span className="service-price">$25</span>
                        </div>
                        <div className="cate-list">
                          <Link className="bg-yellow" to={routes.serviceDetails1}>
                            Construction
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Toughened Glass Fitting Services
                        </Link>
                      </h3>
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <span className="d-inline-block average-rating">
                          (0)
                        </span>
                      </div>
                      <div className="user-info d-flex justify-content-between align-items-center ms-0">
                        <h6 className="ser-contact">
                          <i className="fas fa-phone me-2" />{" "}
                          <span>xxxxxxxx30</span>
                        </h6>
                        <h6 className="ser-location">
                          <span>New Jersey, USA</span>{" "}
                          <i className="fas fa-map-marker-alt ms-1" />
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 theiaStickySidebar">
        <div className="stickybar">
          <div className="sidebar-widget widget">
            <div className="service-amount">
              <span>$45</span>
            </div>
            <div className="service-book">
              <Link
                to="#"
                className="btn btn-primary"
             
              >
                {" "}
                Book Service{" "}
              </Link>
            </div>
          </div>
          <div className="card provider-widget clearfix">
            <div className="card-body">
              <h5 className="card-title">Service Provider</h5>
              <div className="about-author d-flex align-items-center">
                <div className="about-provider-img">
                  <Link to="#">
                    <ImageWithBasePath
                      className="img-fluid rounded-circle"
                      alt="image"
                      src="assets/img/provider/provider-01.jpg"
                    />
                  </Link>
                </div>
                <div className="provider-details">
                  <Link to="#" className="ser-provider-name">
                    Demo Provider
                  </Link>
                  <p className="last-seen">
                    <i className="fas fa-circle online" /> Online
                  </p>
                  <p className="text-muted mb-1">Member Since Sep 2021</p>
                </div>
              </div>
              <hr />
              <div className="provider-info">
                <p className="mb-1">
                  <i className="far fa-envelope" />{" "}
                  <Link to="#">
                    <span>demoprovider@example.com</span>
                  </Link>
                </p>
                <p className="mb-0">
                  <i className="fas fa-phone-alt" /> 91-9988776655
                </p>
              </div>
            </div>
          </div>
          <div className="booking-report-provider">
            <Link to="#">
              <h6>
                {" "}
                <span>
                  {" "}
                  <i className="fas fa-bug me-1" />{" "}
                </span>{" "}
                Report this provider
              </h6>
            </Link>
          </div>
          <div className="card available-widget widget-book-detail">
            <div className="card-body">
              <h5 className="card-title">Service Availability</h5>
              <ul>
                <li>
                  <span>Monday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Tuesday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Wednesday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Thursday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Friday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Saturday</span>9:30 AM - 7:00 PM
                </li>
                <li>
                  <span>Sunday</span>9:30 AM - 7:00 PM
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Content */}
</>

    </>
  )
}

export default ServiceDetails2
