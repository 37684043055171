import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FooterFour from './footer-four';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as Icon from 'react-feather';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../header/home-header';

const homeFour = () => {
  const routes = all_routes;
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [
    { name: 'Select Category' },
    { name: 'Tornoto' },
    { name: 'Texas' },
  ];
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const cateringSlider = {
    dots: true,
    autoplay: false,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const featuresSlider = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const worldSlider = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const clientSlider = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const companySlider = {
    dots: false,
    autoplay: true,
    slidesToShow: 6,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const usefulSlider = {
    dots: true,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <HomeHeader type={4} />
      <div className='home-four-wrapper'>
        {/* Hero Section */}
        <section className="catering-banner-section">
          <div className="container-fluid">
            <div className="home-banner slider-service">
              <div className="row align-items-center w-100">
                <div className="col-lg-7 col-md-10 mx-auto">
                  <div className="section-search section-search-four">
                    <h1>
                      Reviews &amp; Ratings of Best{' '}
                      <span>Catering Service</span> Providers Near You
                    </h1>
                    <p>
                      Helped 10,000+ Customers to find the right caterers for
                      their functions and events around the world.
                    </p>
                    <div className="search-box search-box-four">
                      <form className="search-three-form">
                        <div className="search-input search-input-three search-input-four">
                          <Icon.CheckSquare className="react-feather-custom" />
                          <div className="form-group m-0">
                            <Dropdown
                              value={selectedValue}
                              onChange={(e) => setSelectedValue(e.value)}
                              options={value}
                              optionLabel="name"
                              placeholder="Select Category"
                              className="select border-none"
                            />
                          </div>
                        </div>
                        <div className="search-input search-input-three search-input-four">
                          <Icon.MapPin className="react-feather-custom me-1" />
                          <div className="form-group mb-0">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Location"
                            />
                          </div>
                        </div>
                        <div className="search-btn">
                          <Link className="btn btn-primary" to={routes.search}>
                            <Icon.Search className="react-feather-custom" />
                          </Link>
                        </div>
                      </form>
                    </div>
                    <div className="catering-banner-botton">
                      <div className="catering-btn-services">
                        <div className="catering-btn-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/server.svg"
                            alt="Icon"
                          />
                        </div>
                        <h5>3000+</h5>
                        <h6>Services</h6>
                      </div>
                      <div className="catering-btn-services">
                        <div className="catering-btn-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/Fries.svg"
                            alt="Icon"
                          />
                        </div>
                        <h5>900+</h5>
                        <h6>Food Items</h6>
                      </div>
                      <div className="catering-btn-services">
                        <div className="catering-btn-icon">
                          <ImageWithBasePath
                            src="assets/img/icons/Vector-1.svg"
                            alt="Icon"
                          />
                        </div>
                        <h5>15k Review</h5>
                        <h6>(4.8)</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="catering-banner-img">
                    <ImageWithBasePath
                      src="assets/img/catering-banner.jpg"
                      alt="Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Hero Section */}
        {/* Feature Section */}
        <section className="nearby-section">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row align-items-center">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Nearby Cateres For You</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link
                    to={routes.serviceDetails1}
                    className="btn btn-primary btn-view"
                  >
                    VIEW ALL CATERERS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...cateringSlider}
                  className="catering-slider common-four-slider"
                >
                  <div
                    className="service-widget service-two service-four aos"
                    data-aos="fade-up"
                  >
                    <div className="service-common-four">
                      <div className="service-img">
                        <Link to={routes.serviceDetails1}>
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service-img"
                            src="assets/img/services/service-38.jpg"
                          />
                        </Link>
                      </div>
                      <div className="service-content">
                        <div className="catering-main-bottom">
                          <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled" />
                            <span>(3800 Reviews)</span>
                          </div>
                          <h3 className="title">
                            <Link to={routes.serviceDetails1}>
                              Food Chef Caterings
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img">
                        <ImageWithBasePath
                          src="assets/img/profiles//avatar-03.jpg"
                          alt="User"
                        />
                        <p>Harling Shaw</p>
                      </div>
                      <h6>4.5 KM</h6>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two service-four aos"
                    data-aos="fade-up"
                  >
                    <div className="service-common-four">
                      <div className="service-img">
                        <Link to={routes.serviceDetails1}>
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service-img"
                            src="assets/img/services/service-39.jpg"
                          />
                        </Link>
                      </div>
                      <div className="service-content">
                        <div className="catering-main-bottom">
                          <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled" />
                            <span>(1654 Reviews)</span>
                          </div>
                          <h3 className="title">
                            <Link to={routes.serviceDetails1}>
                              Magnificent Caterers
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img">
                        <ImageWithBasePath
                          src="assets/img/profiles//avatar-04.jpg"
                          alt="User"
                        />
                        <p>Daniel Mathew</p>
                      </div>
                      <h6>6.5 KM</h6>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two service-four aos"
                    data-aos="fade-up"
                  >
                    <div className="service-common-four">
                      <div className="service-img">
                        <Link to={routes.serviceDetails1}>
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service-img"
                            src="assets/img/services/service-40.jpg"
                          />
                        </Link>
                      </div>
                      <div className="service-content">
                        <div className="catering-main-bottom">
                          <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled" />
                            <span>(4577 Reviews)</span>
                          </div>
                          <h3 className="title">
                            <Link to={routes.serviceDetails1}>
                              Food Chef Caterings
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img">
                        <ImageWithBasePath
                          src="assets/img/profiles//avatar-05.jpg"
                          alt="User"
                        />
                        <p>Karen Risb</p>
                      </div>
                      <h6>2.5 KM</h6>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two service-four aos"
                    data-aos="fade-up"
                  >
                    <div className="service-common-four">
                      <div className="service-img">
                        <Link to={routes.serviceDetails1}>
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service-img"
                            src="assets/img/services/service-41.jpg"
                          />
                        </Link>
                      </div>
                      <div className="service-content">
                        <div className="catering-main-bottom">
                          <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled" />
                            <span>(1571 Reviews)</span>
                          </div>
                          <h3 className="title">
                            <Link to={routes.serviceDetails1}>
                              Posh Caterers
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img">
                        <ImageWithBasePath
                          src="assets/img/profiles//avatar-06.jpg"
                          alt="User"
                        />
                        <p>Habibul Akbar</p>
                      </div>
                      <h6>6.5 KM</h6>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two service-four aos"
                    data-aos="fade-up"
                  >
                    <div className="service-common-four">
                      <div className="service-img">
                        <Link to={routes.serviceDetails1}>
                          <ImageWithBasePath
                            className="img-fluid serv-img"
                            alt="Service-img"
                            src="assets/img/services/service-38.jpg"
                          />
                        </Link>
                      </div>
                      <div className="service-content">
                        <div className="catering-main-bottom">
                          <div className="rating">
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled me-1" />
                            <i className="fas fa-star filled" />
                            <span>(3800 Reviews)</span>
                          </div>
                          <h3 className="title">
                            <Link to={routes.serviceDetails1}>
                              Food Chef Caterings
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img">
                        <ImageWithBasePath
                          src="assets/img/profiles//avatar-03.jpg"
                          alt="User"
                        />
                        <p>Harling Shaw</p>
                      </div>
                      <h6>4.5 KM</h6>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* /Feature Section */}
        {/* Categories Section */}
        <section className="categories-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div
                  className="section-heading category-heading aos"
                  data-aos="fade-up"
                >
                  <h2>Catering Service Categories</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-1.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Event Party Caterings</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-2.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Social Function Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-3.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Event Party Caterings </h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-4.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Kitty Party Caterings</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-5.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Buffet Event Caterings</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-6.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>International Caterings</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-7.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Outdoor Event Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-8.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Birthday Party Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-9.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Home Event Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-10.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Engagement Event Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-11.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Home Event Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
                <Link to={routes.serviceDetails1}>
                  <div className="categories-main-all">
                    <div className="categories-img">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/categories-12.svg"
                          alt="Category"
                        />
                      </span>
                    </div>
                    <h6>Indoor Event Catering</h6>
                    <span className="category-bottom">
                      <Icon.ChevronRight className="react-feather-custom" />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="btn-sec btn-catering aos" data-aos="fade-up">
                <Link to={routes.search} className="btn btn-primary btn-view">
                  VIEW ALL CATEGORIES
                  <Icon.ArrowRight className="react-feather-custom" />
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* /Categories Section */}
        {/* Features Services */}
        <section className="features-four-section">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row align-items-center">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Featured Cateres</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link
                    to={routes.serviceDetails1}
                    className="btn btn-primary btn-view"
                  >
                    VIEW ALL CATERERS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...featuresSlider}
                  className=" features-four-slider common-four-slider"
                >
                  <div
                    className="service-widget service-two aos"
                    data-aos="fade-up"
                  >
                    <div className="service-img service-four-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-44.jpg"
                        />
                      </Link>
                      <div className="fav-item">
                        <div className="rate-four">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Swagatham Caters
                        </Link>
                      </h3>
                      <p className="service-cater-bottom">
                        <Icon.MapPin className="react-feather-custom" />
                        New Jersey, USA
                      </p>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <div className="category-feature-bottom">
                        <p>Starting from $500</p>
                        <Link to={''}>Book Now</Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two aos"
                    data-aos="fade-up"
                  >
                    <div className="service-img service-four-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-42.jpg"
                        />
                      </Link>
                      <div className="fav-item">
                        <div className="rate-four">
                          <i className="fas fa-star filled" />
                          <span>4.9</span>
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Delight Catering
                        </Link>
                      </h3>
                      <p className="service-cater-bottom">
                        <Icon.MapPin className="react-feather-custom" />
                        Nevada, USA
                      </p>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Indoor</li>
                        <li>Engagement</li>
                        <li>Kitty Partying</li>
                      </ul>
                      <div className="category-feature-bottom">
                        <p>Starting from $255</p>
                        <Link to={''}>Book Now</Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two aos"
                    data-aos="fade-up"
                  >
                    <div className="service-img service-four-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-43.jpg"
                        />
                      </Link>
                      <div className="fav-item">
                        <div className="rate-four">
                          <i className="fas fa-star filled" />
                          <span>3.8</span>
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Homemade Foods Caters
                        </Link>
                      </h3>
                      <p className="service-cater-bottom">
                        <Icon.MapPin className="react-feather-custom" />
                        Chicago, USA
                      </p>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <div className="category-feature-bottom">
                        <p>Starting from $500</p>
                        <Link to={''}>Book Now</Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className="service-widget service-two aos"
                    data-aos="fade-up"
                  >
                    <div className="service-img service-four-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-41.jpg"
                        />
                      </Link>
                      <div className="fav-item">
                        <div className="rate-four">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Swagatham Caters
                        </Link>
                      </h3>
                      <p className="service-cater-bottom">
                        <Icon.MapPin className="react-feather-custom" />
                        New Jersey, USA
                      </p>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Indoor</li>
                        <li>Engagement</li>
                        <li>Kitty Partying</li>
                        <li>international</li>
                      </ul>
                      <div className="category-feature-bottom">
                        <p>Starting from $500</p>
                        <Link to={''}>Book Now</Link>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* /Features Services */}
        {/* Categories Section */}
        <section className="trust-us-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div
                  className="section-heading category-heading aos"
                  data-aos="fade-up"
                >
                  <h2>Why Trust us</h2>
                  <p>
                    We are growing day by day in terms of catering service
                    providers listing,
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="trust-us-main">
                  <div className="trust-us-img">
                    <Icon.Star size={50} />
                  </div>
                  <h6>Verified Reviews</h6>
                  <p>
                    We has more than 4000 Caterers &amp; catering service
                    companies (and counting) listed with detailed information
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="trust-us-main">
                  <div className="trust-us-img">
                    <Icon.Eye size={50} />
                  </div>
                  <h6>Catering Services</h6>
                  <p>
                    We has more than 4000 Caterers &amp; catering service
                    companies (and counting) listed with detailed information
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="trust-us-main">
                  <div className="trust-us-img">
                    <Icon.FileText size={50} />
                  </div>
                  <h6>Research &amp; Surveys</h6>
                  <p>
                    We has more than 4000 Caterers &amp; catering service
                    companies (and counting) listed with detailed information
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="trust-us-main">
                  <div className="trust-us-img">
                    <Icon.Briefcase size={50} />
                  </div>
                  <h6>Experience In Business</h6>
                  <p>
                    We has more than 4000 Caterers &amp; catering service
                    companies (and counting) listed with detailed information
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Categories Section */}
        {/* Popular Services */}
        <section className="popular-four-section">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row align-items-center">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Popular Locations</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link
                    to={routes.categories}
                    className="btn btn-primary btn-view"
                  >
                    VIEW ALL LOCATIONS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="popular-four-main">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="popular-portfolio">
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-5.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>NewYork</h6>
                          <p>165 Caterings</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-6.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>Los Angels</h6>
                          <p>45 Caterings</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="popular-portfolio">
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-4.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>Istanbul</h6>
                          <p>30 Caterings</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="popular-portfolio">
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-7.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>Paris</h6>
                          <p>47 Caterings</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-8.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>London</h6>
                          <p>78 Caterings</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="popular-portfolio">
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-9.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>Florida</h6>
                          <p>14 Caterings</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="#">
                      <div className="popular-portfolio-img">
                        <ImageWithBasePath
                          src="assets/img/gallery/gallery-img-10.jpg"
                          alt="img"
                        />
                        <div className="popular-portfolio-overlay">
                          <h6>Miami</h6>
                          <p>54 Caterings</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Popular Services */}
        {/* Working Section */}
        <section className="Working-four-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div
                  className="section-heading working-four-heading aos"
                  data-aos="fade-up"
                >
                  <p>Working Steps</p>
                  <h2>How we are working</h2>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="working-four-main">
                  <h6>Step 1</h6>
                  <div className="working-four-img">
                    <ImageWithBasePath
                      src="assets/img/icons/working-1.svg"
                      alt="Work"
                    />
                  </div>
                  <h4>Choose a Service Category</h4>
                  <p>
                    Select the type of catering service you are looking for{' '}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="working-four-main">
                  <h6>Step 2</h6>
                  <div className="working-four-img">
                    <ImageWithBasePath
                      src="assets/img/icons/working-2.svg"
                      alt="Work"
                    />
                  </div>
                  <h4>Choose a Service Category</h4>
                  <p>
                    Select the type of catering service you are looking for{' '}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="working-four-main">
                  <h6>Step 3</h6>
                  <div className="working-four-img">
                    <ImageWithBasePath
                      src="assets/img/icons/working-3.svg"
                      alt="Work"
                    />
                  </div>
                  <h4>Check Catererings Details</h4>
                  <p>
                    Select the type of catering service you are looking for{' '}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="working-four-main">
                  <h6>Step 4</h6>
                  <div className="working-four-img">
                    <ImageWithBasePath
                      src="assets/img/icons/working-4.svg"
                      alt="Work"
                    />
                  </div>
                  <h4>Book the best catererings</h4>
                  <p>
                    Select the type of catering service you are looking for{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*/ Working Section */}
        {/* Around the world  Services */}
        <section className="around-world-section">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row align-items-center">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Top Caterers Around the World</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link
                    to={routes.categories}
                    className="btn btn-primary btn-view"
                  >
                    VIEW ALL CATERERS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...worldSlider}
                  className="world-four-slider common-four-slider"
                >
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-45.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item fav-item-four"
                        key={1}
                        onClick={() => handleItemClick(1)}
                      >
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[1] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info item-info-four">
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          MealMenu Caterings
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <p>
                        <span className="mini-mealmenu">$900</span>
                        <span className="mealmenu">$200</span>
                      </p>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img service-world-img">
                        <Link to={''}>
                          <ImageWithBasePath
                            src="assets/img/profiles//avatar-03.jpg"
                            alt="User"
                          />
                        </Link>
                        <p className="service-cater-bottom">
                          <Icon.MapPin className="react-feather-custom" />
                          New Jersey, USA
                        </p>
                      </div>
                      <span>
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-46.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item fav-item-four"
                        key={2}
                        onClick={() => handleItemClick(2)}
                      >
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[2] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info item-info-four">
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Blue Sea Catering &amp; Banquets
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <p>
                        <span className="mini-mealmenu">$600</span>
                        <span className="mealmenu">$450</span>
                      </p>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img service-world-img">
                        <Link to={''}>
                          <ImageWithBasePath
                            src="assets/img/profiles//avatar-06.jpg"
                            alt="User"
                          />
                        </Link>
                        <p className="service-cater-bottom">
                          <Icon.MapPin className="react-feather-custom" />
                          Main Boulevard, Lahore,
                        </p>
                      </div>
                      <span>
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-47.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item fav-item-four"
                        key={3}
                        onClick={() => handleItemClick(3)}
                      >
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[3] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info item-info-four">
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Popular Hospitality
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <p>
                        <span className="mini-mealmenu">$700</span>
                        <span className="mealmenu">$100</span>
                      </p>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img service-world-img">
                        <Link to={''}>
                          <ImageWithBasePath
                            src="assets/img/profiles//avatar-08.jpg"
                            alt="User"
                          />
                        </Link>
                        <p className="service-cater-bottom">
                          <Icon.MapPin className="react-feather-custom" />
                          USA Peachfield Road, Uk
                        </p>
                      </div>
                      <span>
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-46.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item fav-item-four"
                        key={4}
                        onClick={() => handleItemClick(4)}
                      >
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[4] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info item-info-four">
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                    </div>
                    <div className="service-content service-four-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Blue Sea Catering &amp; Banquets
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <ul>
                        <li>Buffet Caterings</li>
                        <li>Engagement</li>
                      </ul>
                      <p>
                        <span className="mini-mealmenu">$600</span>
                        <span className="mealmenu">$450</span>
                      </p>
                    </div>
                    <div className="service-content-bottom">
                      <div className="service-cater-img service-world-img">
                        <Link to={''}>
                          <ImageWithBasePath
                            src="assets/img/profiles//avatar-09.jpg"
                            alt="User"
                          />
                        </Link>
                        <p className="service-cater-bottom">
                          <Icon.MapPin className="react-feather-custom" />
                          Main Boulevard, Lahore,
                        </p>
                      </div>
                      <span>
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* /Around the world  Services */}
        <section className="review-client-section custom-slider-cls">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row align-items-center">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Reviews from our Clients</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link to="#" className="btn btn-primary btn-view">
                    VIEW ALL REVIEWS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...clientSlider}
                  className="client-four-slider common-four-slider"
                >
                  <div className="client-review-main">
                    <div className="client-review-top">
                      <div className="client-review-name">
                        <h6>Daniela Fransis</h6>
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua reader will be distracted Loremut labore et
                        dolore.
                      </p>
                    </div>
                    <div className="client-review-img">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-15.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                  <div className="client-review-main">
                    <div className="client-review-top">
                      <div className="client-review-name">
                        <h6>Hashimoda Reena</h6>
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua reader will be distracted Loremut labore et
                        dolore.
                      </p>
                    </div>
                    <div className="client-review-img">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-19.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                  <div className="client-review-main">
                    <div className="client-review-top">
                      <div className="client-review-name">
                        <h6>Jake Sulaine</h6>
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua reader will be distracted Loremut labore et
                        dolore.
                      </p>
                    </div>
                    <div className="client-review-img">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-18.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                  <div className="client-review-main">
                    <div className="client-review-top">
                      <div className="client-review-name">
                        <h6>Daniela Fransis</h6>
                        <div className="rating">
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled me-1" />
                          <i className="fas fa-star filled" />
                        </div>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua reader will be distracted Loremut labore et
                        dolore.
                      </p>
                    </div>
                    <div className="client-review-img">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-15.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <Slider {...companySlider} className="testimonial-slider aos">
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-01.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-02.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-03.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-06.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-04.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-05.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-03.svg"
                  alt="Logo"
                />
              </div>
              <div className="company-logos">
                <ImageWithBasePath
                  src="assets/img/icons/logo-06.svg"
                  alt="Logo"
                />
              </div>
            </Slider>
          </div>
        </section>
        {/* /Reviews from our Clients */}
        {/* Interesting & Useful Blogs section */}
        <section className="useful-blog-section">
          <div className="container">
            <div className="section-heading section-heading-four">
              <div className="row">
                <div className="col-md-6 aos" data-aos="fade-up">
                  <h2>Interesting &amp; Useful Blogs</h2>
                  <p>
                    Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
                    vehicula
                  </p>
                </div>
                <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                  <Link
                    to={routes.blogDetails}
                    className="btn btn-primary btn-view"
                  >
                    VIEW ALL BLOGS
                    <Icon.ArrowRight className="react-feather-custom" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...usefulSlider}
                  className="useful-four-slider common-four-slider"
                >
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.blogDetails}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-48.jpg"
                        />
                      </Link>
                    </div>
                    <div className="service-content service-four-blogs">
                      <h3 className="title">
                        <Link to={routes.blogDetails}>
                          Lorem Ipsum is simply dummy text of the{' '}
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <div className="usefull-bottom">
                        <h6>by Christopher</h6>
                        <div className="useful-img">
                          <Link to={routes.customerProfile}>
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-10.jpg"
                              alt="User"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.blogDetails}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-49.jpg"
                        />
                      </Link>
                    </div>
                    <div className="service-content service-four-blogs">
                      <h3 className="title">
                        <Link to={routes.blogDetails}>
                          Lorem Ipsum is simply dummy text of the{' '}
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <div className="usefull-bottom">
                        <h6>by James</h6>
                        <div className="useful-img">
                          <Link to={routes.customerProfile}>
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-11.jpg"
                              alt="User"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.blogDetails}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-50.jpg"
                        />
                      </Link>
                    </div>
                    <div className="service-content service-four-blogs">
                      <h3 className="title">
                        <Link to={routes.blogDetails}>
                          Lorem Ipsum is simply dummy text of the{' '}
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <div className="usefull-bottom">
                        <h6>by Matthew</h6>
                        <div className="useful-img">
                          <Link to={routes.customerProfile}>
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-12.jpg"
                              alt="User"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.blogDetails}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service-img"
                          src="assets/img/services/service-49.jpg"
                        />
                      </Link>
                    </div>
                    <div className="service-content service-four-blogs">
                      <h3 className="title">
                        <Link to={routes.blogDetails}>
                          Lorem Ipsum is simply dummy text of the{' '}
                        </Link>
                      </h3>
                      <p>
                        we are also engage in offering catering services and
                        decoration services where vision...
                      </p>
                      <div className="usefull-bottom">
                        <h6>by George paul</h6>
                        <div className="useful-img">
                          <Link to={routes.customerProfile}>
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-15.jpg"
                              alt="User"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>
        {/* /Interesting & Useful Blogs section */}
      </div>
      <FooterFour />
    </>
  );
};

export default homeFour;
