import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const CustomerFavourite = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(true));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-sm-6">
          <div className="widget-title">
            <h4>Favorites</h4>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-end">
          <div className="review-sort cus-fav-sort me-2">
            <p>Sort</p>
            <Dropdown
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.value)}
              options={value}
              optionLabel="name"
              placeholder="A - Z"
              className="select cus-fav"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to="#">
                  <Icon.Filter className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerFavourite} className="active">
                  <Icon.Grid className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerBooking}>
                  <Icon.List className="standard-feather" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
      <div className="row">
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-04.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={1}
                onClick={() => handleItemClick(1)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Car Wash</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[1] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-01.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>Car Repair Services</Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Maryland City, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$50.00</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-02.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={2}
                onClick={() => handleItemClick(2)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Construction</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[2] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-01.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>
                  Toughened Glass Fitting Services
                </Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                New Jersey, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$25.00</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-06.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={3}
                onClick={() => handleItemClick(3)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Computer</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[3] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-02.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>
                  Computer &amp; Server AMC Service
                </Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                California, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>
                  $20.00<span className="old-price">$35.00</span>
                </h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-07.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={4}
                onClick={() => handleItemClick(4)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Interior</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[4] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-03.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>Interior Designing</Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Maryland, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>
                  $5.00<span className="old-price">$25.00</span>
                </h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-08.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={5}
                onClick={() => handleItemClick(5)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Car Wash</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[5] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-04.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>Steam Car Wash</Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Montana, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$14.00</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-01.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={6}
                onClick={() => handleItemClick(6)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Electrical</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[6] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-05.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>
                  Electric Panel Repairing Service
                </Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Texas, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$45</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-09.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={7}
                onClick={() => handleItemClick(7)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Cleaning</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[7] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-05.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>House Cleaning Services</Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Georgia
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$10.00</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-05.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={8}
                onClick={() => handleItemClick(8)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Construction</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[8] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-06.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>
                  Commercial Painting Services
                </Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Alabama, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>
                  $10.00<span className="old-price">$15.00</span>
                </h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
        {/* Service List */}
        <div className="col-xl-4 col-md-6">
          <div className="service-widget servicecontent">
            <div className="service-img">
              <Link to={routes.serviceDetails1}>
                <ImageWithBasePath
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src="assets/img/services/service-10.jpg"
                />
              </Link>
              <div
                className="fav-item"
                key={9}
                onClick={() => handleItemClick(9)}
              >
                <Link to={routes.categories}>
                  <span className="item-cat">Construction</span>
                </Link>
                <Link
                  to="#"
                  className={`fav-icon ${selectedItems[9] ? 'selected' : ''}`}
                >
                  <Icon.Heart size={15} />
                </Link>
              </div>
              <div className="item-info">
                <Link to={routes.providers}>
                  <span className="item-img">
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-01.jpg"
                      className="avatar"
                      alt="User"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={routes.serviceDetails1}>
                  Building Construction Services
                </Link>
              </h3>
              <p>
                <Icon.MapPin className="react-feather-custom" />
                Montana, USA
                <span className="rate">
                  <i className="fas fa-star filled" />
                  4.9
                </span>
              </p>
              <div className="serv-info">
                <h6>$75</h6>
                <Link to={routes.booking1} className="btn btn-book">
                  Book Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Service List */}
      </div>
      {/* Pagination */}
      <div className="row">
        <div className="col-sm-6">
          <div className="review-entries">
            <span>Show</span>
            <select>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span>entries</span>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="review-pagination">
            <p>1 - 9 of 10</p>
            <ul className="pagination">
              <li className="page-item">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  2 <span className="visually-hidden">(current)</span>
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Pagination */}
    </div>
  );
};

export default CustomerFavourite;
