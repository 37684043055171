import React, { useState } from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const Login = () => {
  const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
     <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Login</h3>
                  <p>Please enter your details</p>
                  <h6>
                    Sign in with <Link to={routes.loginphone}>Phone Number</Link>
                  </h6>
                </div>
                {/* Login Form */}
                <form action={routes.customerDashboard}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="johndoe@example.com"
                      />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">Password</label>
                        </div>
                        <div className="col-auto">
                          <Link
                            className="forgot-link"
                            to={routes.passwordRecovery}
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="pass-group">
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          className="form-control pass-input"
                          placeholder="*************"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <Icon.EyeOff className="react-feather-custom"/> : <Icon.Eye className="react-feather-custom"/>}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            className="rememberme"
                          />
                          <span className="checkmark" />
                          Remember Me
                        </label>
                      </div>
                      <div className="col-6 text-end">
                        <label className="custom_check">
                          <input
                            type="checkbox"
                            name="loginotp"
                            className="loginotp"
                          />
                          <span className="checkmark" />
                          Login with OTP
                        </label>
                      </div>
                    </div>
                  </div>
                  <Link to={routes.customerDashboard}
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Login
                  </Link>
                  <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">OR</span>
                  </div>
                  <div className="social-login">
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="me-2"
                        alt="img"
                      />
                      Login with Google
                    </Link>
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/fb.svg"
                        className="me-2"
                        alt="img"
                      />
                      Login with Facebook
                    </Link>
                  </div>
                  <p className="no-acc">
                    Donot have an account ?{' '}
                    <Link to={routes.chooseSignUp}>Register</Link>
                  </p>
                </form>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
