import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const PrivacyPolicy = () => {
  const routes = all_routes;
  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Privacy Policy</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}

            <div className="col-md-12">
              <div className="terms-content privacy-cont">
                <h2>Privacy Policy for Vimaid Lifetech Limited</h2> <br />
                <ol>
                  <li>
                    <strong>Introduction</strong>
                    <p>
                      Vimaid Lifetech Limited (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is
                      committed to safeguarding the privacy of our users (&quot;you&quot;
                      or &quot;your&quot;). This privacy policy explains how we collect,
                      use, disclose, and protect your personal information when
                      you use our services.
                    </p>
                  </li>
                  <li>
                    <strong>Information We Collect</strong>
                    <p>We may collect the following types of information:</p>
                    <ul>
                      <li>
                        Personal Identification Information: Full name, email
                        address, phone number, billing and mailing addresses,
                        etc.
                      </li>
                      <li>
                        Service Information: Details related to the services you
                        request or provide through our platform.
                      </li>
                      <li>
                        Technical Information: Device information, IP address,
                        browser type, and operating system.
                      </li>
                      <li>
                        Usage Information: Details about your interaction with
                        our platform, such as pages visited, actions taken, and
                        referring URLs.
                      </li>
                      <li>
                        Location Information: Your geographical location,
                        provided you enable location services on your device.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>How We Use Your Information</strong>
                    <p>
                      We use the collected information for the following
                      purposes:
                    </p>
                    <ul>
                      <li>To provide, maintain, and improve our services.</li>
                      <li>
                        To verify your identity and authenticate your account.
                      </li>
                      <li>
                        To facilitate service requests and manage bookings.
                      </li>
                      <li>
                        To communicate with you, including sending notifications
                        and updates.
                      </li>
                      <li>
                        To personalize your experience and tailor services based
                        on your preferences.
                      </li>
                      <li>
                        To analyze trends, usage patterns, and marketing
                        effectiveness.
                      </li>
                      <li>
                        To comply with legal obligations and prevent fraud or
                        abuse.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Sharing of Information</strong>
                    <p>We may share your personal information with:</p>
                    <ul>
                      <li>
                        Service Providers: To facilitate the provision of
                        requested services.
                      </li>
                      <li>
                        Business Partners: To provide joint marketing,
                        advertising, or co-branded services.
                      </li>
                      <li>
                        Legal Authorities: When required by law, regulation, or
                        in response to a legal request.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Data Security</strong>
                    <p>
                      We employ reasonable security measures to protect your
                      personal information from unauthorized access, disclosure,
                      alteration, or destruction. Despite our efforts, no method
                      of electronic storage is 100% secure.
                    </p>
                  </li>
                  <li>
                    <strong>Retention Period</strong>
                    <p>
                      We retain your information for as long as necessary to
                      fulfill the purposes outlined in this policy unless a
                      longer retention period is required or permitted by law.
                    </p>
                  </li>
                  <li>
                    <strong>Your Rights</strong>
                    <p>You have the right to:</p>
                    <ul>
                      <li>
                        Access and obtain a copy of your personal information.
                      </li>
                      <li>
                        Request the correction or deletion of inaccurate
                        information.
                      </li>
                      <li>
                        Object to or restrict the processing of your personal
                        data.
                      </li>
                      <li>
                        Withdraw consent at any time if processing is based on
                        your consent.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Changes to This Privacy Policy</strong>
                    <p>
                      We may update this policy from time to time to reflect
                      changes in our practices or legal requirements. We will
                      notify you of significant changes through email or by
                      updating the policy on our website.
                    </p>
                  </li>
                  <li>
                    <strong>Contact Information</strong>
                    <p>
                      For any questions or concerns about this privacy policy or
                      how we handle your information, please contact us at{' '}
                      <a href="mailto:muhammed@vimaid.com">
                        muhammed@vimaid.com
                      </a>
                      .
                    </p>
                  </li>
                </ol>
              </div>
            </div>

            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
