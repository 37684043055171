import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { all_routes } from '../../../../core/data/routes/all_routes';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { useSelector } from 'react-redux';
import * as Icon from 'react-feather';
import { CurrentRoute } from '../../../../core/models/interface';

const FooterOne = () => {
  const routes = all_routes;
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);

  const languages = [
    { name: 'English' },
    { name: 'French' },
    { name: 'Spanish' },
  ];
  const currencies = [
    { name: 'US Dollars' },
    { name: 'UK Pounds' },
    { name: 'Euro' },
  ];
  const current_route_array = useSelector(
    (state: CurrentRoute) => state.current_route_array,
  );

  return (
    <>
      {!current_route_array.includes('authentication') ? (
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget">
                    <div className="footer-logo">
                      <Link to={'/'}>
                        <ImageWithBasePath
                          src="assets/img/logo.svg"
                          alt="Company Logo"
                        />
                      </Link>
                    </div>
                    <div className="footer-content">
                      <p>
                        Our company specializes in providing quality services
                        that meet your needs. We strive to deliver exceptional
                        value to our clients.
                      </p>
                    </div>
                    <div className="footer-selects">
                      <h2 className="footer-title">Language & Currency</h2>
                      <div className="row">
                        <div className="col-lg-12 d-flex">
                          <div className="footer-select">
                            <ImageWithBasePath
                              src="assets/img/icons/global.svg"
                              alt="Language Icon"
                            />
                            <Dropdown
                              value={selectedLanguage}
                              onChange={(e) => setSelectedLanguage(e.value)}
                              options={languages}
                              optionLabel="name"
                              placeholder="English"
                              className="select footer-react-select"
                            />
                          </div>
                          <div className="footer-select">
                            <ImageWithBasePath
                              src="assets/img/icons/dropdown.svg"
                              className="footer-dropdown"
                              alt="Currency Icon"
                            />

                            <Dropdown
                              value={selectedValue}
                              onChange={(e) => setselectedValue(e.value)}
                              options={currencies}
                              optionLabel="name"
                              placeholder="US Dollars"
                              className="select footer-react-select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">Quick Links</h2>
                    <ul>
                      <li>
                        <Link to={routes.aboutUs}>About Us</Link>
                      </li>
                      <li>
                        <Link to={routes.blogGrid}>Blogs</Link>
                      </li>
                      <li>
                        <Link to={routes.contactUs}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={routes.providerSignup}>
                          Become a Professional
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.userSignup}>Become a User</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget footer-contact">
                    <h2 className="footer-title">Contact Us</h2>
                    <div className="footer-contact-info">
                      <div className="footer-address">
                        <p>
                          <span>
                            <Icon.MapPin className="react-feather-custom" />
                          </span>{' '}
                          71-75 SHELTON STREET COVENT GARDEN LONDON UNITED
                          KINGDOM WC2H 9JQ
                        </p>
                      </div>
                      <p>
                        <span>
                          <Icon.Phone className="react-feather-custom" />
                        </span>{' '}                        
                        <a href="https://wa.me/+447445438041">+44 7445 438041</a>
                      </p>
                      <p className="mb-0">
                        <span>
                          <Icon.Mail className="react-feather-custom" />
                        </span>{' '}
                        {/* mailto and write an ready message to info@vimaid.com */}
                        <a href="mailto:info@vimaid.com?cc=muhammed@vimaid.com&subject=Vimaid Info&body=Hi I would like to be informed about">info@vimaid.com</a>               
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget">
                    <h2 className="footer-title">Follow Us</h2>
                    <div className="social-icon">
                      <ul>
                        <li>
                          <Link to={'https://www.facebook.com/vimaidlife'} target="_blank">
                            <i className="fa-brands fa-facebook"></i>{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to={'https://www.twitter.com/vimaidlife'} target="_blank">
                            <i className="fab fa-twitter"></i>{' '}
                          </Link>
                        </li>
                        <li>
                          <Link to={'https://www.instagram.com/vimaidlife'} target="_blank">
                            <i className="fa-brands fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to={'https://www.linkedin.com/in/vimaidlife'} target="_blank">
                            <i className="fa-brands fa-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <h2 className="footer-subtitle">Newsletter Signup</h2>
                    <div className="subscribe-form">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                      />
                      <button type="submit" className="btn footer-btn">
                        <Icon.Send className="react-feather-custom" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container">
              <div className="copyright">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="copyright-text">
                      <p className="mb-0">
                        Copyright &copy; 2023. All Rights Reserved.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="payment-image">
                      <ul>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/visa.png"
                              alt="Visa"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/mastercard.png"
                              alt="MasterCard"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/stripe.png"
                              alt="Stripe"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to={'/'}>
                            <ImageWithBasePath
                              src="assets/img/payment/discover.png"
                              alt="Discover"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="copyright-menu">
                      <ul className="policy-menu">
                        <li>
                          <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to={routes.termsCondition}>
                            Terms & Conditions
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : null}
    </>
  );
};

export default FooterOne;
