import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PhoneInput from 'react-phone-input-2';
import { all_routes } from '../../../../core/data/routes/all_routes';

const SecuritySetting = () => {
  const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility2 = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility3 = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility4 = () => {
    setPasswordVisible(!passwordVisible);
  };
  const togglePasswordVisibility5 = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [phone, setPhone] = useState('');
  const handleOnChange = (value: string, country: string) => {
    console.log(value, country);
    setPhone(value);
  };
  return (
    <>
      {/* Security Settings */}
      <div className="col-lg-9">
        <div className="widget-title">
          <h4>Security Settings</h4>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-9">
              <div className="linked-acc mb-sm-3 mb-md-0">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/lock-icon.svg"
                    alt="Lock"
                  />
                </span>
                <div className="linked-info">
                  <h6>Password</h6>
                  <p>
                    Last Changed <span>22 Sep 2023, 10:30:55 AM</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 text-md-end">
              <button
                className="btn btn-secondary btn-set"
                data-bs-toggle="modal"
                data-bs-target="#change-password"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-8">
              <div className="linked-acc mb-sm-3 mb-md-0">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/scan-icon.svg"
                    alt="Search"
                  />
                </span>
                <div className="linked-info">
                  <h6>
                    Two Factor Authendication{' '}
                    <span className="badge badge-success">Enable</span>
                  </h6>
                  <p>Lorem ipsum dolor sit amet, consectetur </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <div className="d-flex align-items-center justify-content-md-end">
                <Link to="#" className="btn btn-secondary btn-set">
                  Disable
                </Link>
                <div className="status-toggle status-tog d-inline-flex justify-content-between align-items-center">
                  <input
                    type="checkbox"
                    id="status_1"
                    className="check"
                    checked={true}
                  />
                  <label htmlFor="status_1" className="checktoggle">
                    checkbox
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-8">
              <div className="linked-acc">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/mobile-icon.svg"
                    alt="image"
                  />
                </span>
                <div className="linked-info">
                  <h6>Phone Number Verification</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <div className="security-verifi d-flex align-items-center">
                <Link to="#" className="link-close">
                  <i className="fa-solid fa-circle-xmark" />
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary btn-connect"
                  data-bs-toggle="modal"
                  data-bs-target="#change-phone"
                >
                  Change
                </Link>
                <Link to="#" className="btn-acc">
                  Remove
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-8">
              <div className="linked-acc">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/scan-icon.svg"
                    alt="Search"
                  />
                </span>
                <div className="linked-info">
                  <h6>Email Verification</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <div className="security-verifi d-flex align-items-center">
                <Link to="#" className="link-check">
                  <i className="fa-solid fa-circle-check" />
                </Link>
                <Link
                  to="#"
                  className="btn btn-primary btn-connect"
                  data-bs-toggle="modal"
                  data-bs-target="#change-email"
                >
                  Change
                </Link>
                <Link to="#" className="btn-acc">
                  Remove
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-8">
              <div className="linked-acc mb-sm-3 mb-md-0">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/monitor-icon.svg"
                    alt="image"
                  />
                </span>
                <div className="linked-info">
                  <h6>Device Management</h6>
                  <p>
                    Last Changed <span>22 Sep 2023, 10:30:55 AM</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <Link
                to={routes.deviceManagement}
                className="btn btn-secondary btn-set"
              >
                Manage
              </Link>
            </div>
          </div>
        </div>
        <div className="linked-item">
          <div className="row">
            <div className="col-md-8">
              <div className="linked-acc">
                <span className="link-icon">
                  <ImageWithBasePath
                    src="assets/img/icons/user-edit.svg"
                    alt="image"
                  />
                </span>
                <div className="linked-info">
                  <h6>Account Activity</h6>
                  <p>
                    Last login <span>22 Sep 2023, 10:21:55 AM</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-md-end">
              <Link
                to={routes.loginActivity}
                className="btn btn-secondary btn-set"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* /Security Settings */}
      {/* Change Password  */}
      <div className="modal fade custom-modal" id="change-password">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Change Password</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action={routes.success}>
                <div className="form-group">
                  <label className="col-form-label">Current Password</label>
                  <div className="pass-group">
                    <input
                      type="password"
                      className="form-control pass-input"
                      placeholder="*************"
                    />
                    <span
                      className="toggle-password"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <Icon.EyeOff /> : <Icon.Eye />}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">New Password</label>
                  <div className="pass-group" id="passwordInput">
                    <input
                      type="password"
                      className="form-control pass-input"
                      placeholder="*************"
                    />
                    <span
                      className="toggle-password"
                      onClick={togglePasswordVisibility2}
                    >
                      {passwordVisible ? <Icon.EyeOff /> : <Icon.Eye />}
                    </span>
                  </div>
                  <div className="password-strength" id="passwordStrength">
                    <span id="poor" />
                    <span id="weak" />
                    <span id="strong" />
                    <span id="heavy" />
                  </div>
                  <div id="passwordInfo">
                    Use 8 or more characters with a mix of letters, numbers
                    &amp; symbols.
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Confirm New Password</label>
                  <div className="pass-group">
                    <input
                      type="password"
                      className="form-control pass-input"
                      placeholder="*************"
                    />
                    <span
                      className="toggle-password"
                      onClick={togglePasswordVisibility3}
                    >
                      {passwordVisible ? <Icon.EyeOff /> : <Icon.Eye />}
                    </span>
                  </div>
                </div>
                <div className="acc-submit">
                  <Link to="#" className="btn btn-secondary">
                    Cancel
                  </Link>
                  <button className="btn btn-primary" type="submit">
                    Update Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Password  */}
      {/* Change Email */}
      <div className="modal fade custom-modal" id="change-email">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Change Email</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action={routes.ProviderSecuritySettings}>
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label">
                      Current Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      New Email Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                    />
                    <p className="brief-bio mb-0 mt-2">
                      <i className="feather-alert-circle" /> New Email Address
                      Only Updated Once You Verified
                    </p>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      Confirm New Password
                    </label>
                    <div className="pass-group">
                      <input
                        type="password"
                        className="form-control pass-input"
                        placeholder="*************"
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility4}
                      >
                        {passwordVisible ? <Icon.EyeOff /> : <Icon.Eye />}
                      </span>
                    </div>
                  </div>
                  <div className="modal-submit text-end">
                    <Link
                      to="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button className="btn btn-primary">Change Email</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Email */}
      {/* Change Phone  */}
      <div className="modal fade custom-modal" id="change-phone">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Change Phone Number</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action={routes.ProviderSecuritySettings}>
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label">
                      Current Phone Number
                    </label>
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      onChange={handleOnChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      New Phone Number <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      country={'us'}
                      value={phone}
                      onChange={handleOnChange}
                    />
                    <p className="brief-bio mb-0 mt-2">
                      <i className="feather-alert-circle" /> New Phone Number
                      Only Updated Once You Verified
                    </p>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      Confirm New Password
                    </label>
                    <div className="pass-group">
                      <input
                        type="password"
                        className="form-control pass-input"
                        placeholder="*************"
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility5}
                      >
                        {passwordVisible ? <Icon.EyeOff /> : <Icon.Eye />}
                      </span>
                    </div>
                  </div>
                  <div className="modal-submit text-end">
                    <Link
                      to="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button className="btn btn-primary">Change Number</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Change Phone  */}
    </>
  );
};

export default SecuritySetting;
