import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';

const ProviderDashboard = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Provider Dashboard</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.value)}
                    options={value}
                    optionLabel="name"
                    placeholder="A - Z"
                    className="select provider-select select-space"
                  />
                </div>
                <div className="grid-listview">
                  <ul>
                    <li>
                      <Link to="#">
                        <Icon.Filter className="react-feather-custom" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-xl-3 col-sm-6 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-04.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Earnings</h6>
                        <h5>$50000</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2022</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Total Sales: <span>85</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-01.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-03.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Bookings</h6>
                        <h5>102</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Over All: <span>60</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-02.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-02.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Services</h6>
                        <h5>12</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Providers: <span>120</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-03.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-01.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Balance</h6>
                        <h5>$200</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <Link to="#" className="btn btn-primary">
                      Withdraw
                    </Link>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-04.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex flex-column">
              {/* Current Plan */}
              <h6 className="subhead-title">Current Plan</h6>
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Standard</h5>
                      <p>Our most popular plan for small teams.</p>
                      <h6>
                        <span>Renew Date:</span> Jan 22, 2023
                      </h6>
                    </div>
                    <div className="plan-price">
                      <h4>$291</h4>
                      <p>per user/Year</p>
                    </div>
                  </div>
                  <div className="plan-btns">
                    <Link to="#" className="btn btn-danger-light">
                      Cancel Subscription
                    </Link>
                    <Link
                      to={routes.providerSubscription}
                      className="btn btn-secondary-outline"
                    >
                      Upgrade
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Current Plan */}
            </div>
            <div className="col-md-6 d-flex flex-column">
              {/* Payments */}
              <h6 className="subhead-title">Payment</h6>
              <div className="card flex-fill">
                <div className="card-body pay-next">
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Last Payment</h5>
                      <h6>January 22, 2023</h6>
                    </div>
                    <div className="plan-price">
                      <Link to="#" className="btn btn-light-success">
                        Active
                      </Link>
                    </div>
                  </div>
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Next Payment</h5>
                      <h6>January 22, 2023</h6>
                    </div>
                    <div className="plan-price">
                      <Link to="#" className="btn btn-primary">
                        Manage Payment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* Payments */}
            </div>
            <div className="col-md-12">
              <h6 className="subhead-title">Recent Booking</h6>
              {/* Booking List */}
              <div className="booking-list">
                <div className="booking-widget">
                  <div className="booking-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        src="assets/img/services/service-08.jpg"
                        alt="User Image"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={1}
                      onClick={() => handleItemClick(1)}
                    >
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[1] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="booking-det-info">
                    <h3>
                      <Link to={routes.serviceDetails1}>Computer Services</Link>
                      <span className="badge badge-danger">Cancelled</span>
                    </h3>
                    <ul className="booking-details">
                      <li>
                        <span className="book-item">Booking Date</span> : 27 Sep
                        2023, 17:00-18:00
                      </li>
                      <li>
                        <span className="book-item">Amount</span> : $100.00{' '}
                        <span className="badge-grey">COD</span>
                      </li>
                      <li>
                        <span className="book-item">Location</span> : Newyork,
                        USA
                      </li>
                      <li>
                        <span className="book-item">Customer</span> :
                        <div className="user-book">
                          <div className="avatar avatar-xs">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </div>
                          John Doe
                        </div>
                        <p>info@johndoe.com</p>
                        <p>+1 888 888 8888</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="booking-action">
                  <Link to={routes.booking1} className="btn btn-secondary">
                    Reschedule
                  </Link>
                </div>
              </div>
              {/* /Booking List */}
              {/* Booking List */}
              <div className="booking-list">
                <div className="booking-widget">
                  <div className="booking-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        src="assets/img/services/service-18.jpg"
                        alt="User Image"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={2}
                      onClick={() => handleItemClick(2)}
                    >
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[2] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="booking-det-info">
                    <h3>
                      <Link to={routes.serviceDetails1}>Car Repair Services</Link>{' '}
                      <span className="badge badge-success">Completed</span>
                    </h3>
                    <ul className="booking-details">
                      <li>
                        <span className="book-item">Booking Date</span> : 23 Sep
                        2023, 10:00-11:00
                      </li>
                      <li>
                        <span className="book-item">Amount</span> : $50.00{' '}
                        <span className="badge-grey">COD</span>
                      </li>
                      <li>
                        <span className="book-item">Location</span> : Alabama,
                        USA
                      </li>
                      <li>
                        <span className="book-item">Customer</span> :
                        <div className="user-book">
                          <div className="avatar avatar-xs">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </div>
                          John Smith
                        </div>
                        <p>info@johnsmith.com</p>
                        <p>+1 607-276-5393</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="booking-action">
                  <Link to={routes.booking1} className="btn btn-primary">
                    Rebook
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#add-review"
                  >
                    <Icon.PlusCircle className="react-feather-custom" /> Add
                    Review
                  </Link>
                  <div className="view-action">
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                    </div>
                    <Link to={routes.serviceDetails1} className="view-btn">
                      View Details
                    </Link>
                  </div>
                </div>
              </div>
              {/* /Booking List */}
              {/* Booking List */}
              <div className="booking-list">
                <div className="booking-widget">
                  <div className="booking-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        src="assets/img/services/service-07.jpg"
                        alt="User Image"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={3}
                      onClick={() => handleItemClick(3)}
                    >
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[3] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="booking-det-info">
                    <h3>
                      <Link to={routes.serviceDetails1}>Interior Designing</Link>{' '}
                      <span className="badge badge-info">Inprogress</span>
                    </h3>
                    <ul className="booking-details">
                      <li>
                        <span className="book-item">Booking Date</span> : 27 Sep
                        2023, 17:00-18:00
                      </li>
                      <li>
                        <span className="book-item">Amount</span> : $50.00{' '}
                        <span className="badge-grey">COD</span>
                      </li>
                      <li>
                        <span className="book-item">Location</span> :
                        Washington, DC, USA
                      </li>
                      <li>
                        <span className="book-item">Customer</span> :
                        <div className="user-book">
                          <div className="avatar avatar-xs">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src="assets/img/profiles/avatar-06.jpg"
                            />
                          </div>
                          Quentin
                        </div>
                        <p>info@quentin.com</p>
                        <p>+1 601-810-9218</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="booking-action">
                  <Link to={routes.providerChat} className="btn btn-primary">
                    <Icon.MessageSquare className="react-feather-custom" /> Chat
                  </Link>
                  <Link to="#" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </div>
              {/* /Booking List */}
              {/* Booking List */}
              <div className="booking-list">
                <div className="booking-widget">
                  <div className="booking-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        src="assets/img/services/service-13.jpg"
                        alt="User Image"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={4}
                      onClick={() => handleItemClick(4)}
                    >
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[4] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="booking-det-info">
                    <h3>
                      <Link to={routes.serviceDetails1}>
                        House Cleaning Services
                      </Link>{' '}
                      <span className="badge badge-warning">Pending</span>
                    </h3>
                    <ul className="booking-details">
                      <li>
                        <span className="book-item">Booking Date</span> : 27 Sep
                        2023, 17:00-18:00
                      </li>
                      <li>
                        <span className="book-item">Amount</span> : $375.00{' '}
                        <span className="badge-grey">COD</span>
                      </li>
                      <li>
                        <span className="book-item">Location</span> : Virginia,
                        USA
                      </li>
                      <li>
                        <span className="book-item">Customer</span> :
                        <div className="user-book">
                          <div className="avatar avatar-xs">
                            <ImageWithBasePath
                              className="avatar-img rounded-circle"
                              alt="User Image"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </div>
                          Johnson
                        </div>
                        <p>info@johnson.com</p>
                        <p>+1 312-328-3970</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="booking-action">
                  <Link to={routes.providerChat} className="btn btn-primary">
                    <Icon.MessageSquare className="react-feather-custom" /> Chat
                  </Link>
                  <Link to="#" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </div>
              {/* /Booking List */}
              <div className="view-history">
                <Link to={routes.providerBooking} className="btn btn-primary">
                  View All History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderDashboard;
