import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const BookingDone = () => {
  const routes = all_routes;
  return (
    <div>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3 w-50"
        />
      </div>
      <div className="content book-content">
        <div className="container">
          <div className="row">
            {/* Booking */}
            <div className="col-lg-10 mx-auto">
              {/* Booking Step */}
              <ul className="step-register row">
                <li className="activate col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li className="activate col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/wallet-icon.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Payment</h6>
                    <p>Select Payment Gateway</p>
                  </div>
                </li>
                <li className="active col-md-4">
                  <div className="multi-step-icon">
                    <span>
                      <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                    </span>
                  </div>
                  <div className="multi-step-info">
                    <h6>Done </h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>
              {/* /Booking Step */}
              {/* Booking Done */}
              <div className="row align-items-center">
                <div className="col-md-7">
                  <div className="booking-done">
                    <h6>Successfully Completed Payment</h6>
                    <p>Your Booking has been Successfully Competed</p>
                    <div className="book-submit">
                      <Link to={routes.homeOne} className="btn btn-primary">
                        <Icon.ArrowLeftCircle className="react-feather-custom" />{' '}
                        Go to Home
                      </Link>
                      <Link to="#" className="btn btn-secondary">
                        <i className="fa-solid fa-calendar-days me-2" />
                        Add to Calender
                      </Link>
                      <Link
                        to={routes.customerBooking}
                        className="btn btn-secondary"
                      >
                        Booking History
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="booking-done">
                    <ImageWithBasePath
                      src="assets/img/booking-done.png"
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                </div>
              </div>
              {/* /Booking Done */}
            </div>
            {/* /Booking */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDone;
