import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const CommingSoon = () => {
  const routes = all_routes;
  return (
    <>
      <div className="main-wrapper">
        <div className="bg-img">
          <ImageWithBasePath
            src="assets/img/bg/work-bg-03.png"
            alt="img"
            className="bgimg1"
          />
          <ImageWithBasePath
            src="assets/img/bg/work-bg-03.png"
            alt="img"
            className="bgimg2"
          />
        </div>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="error-wrap text-center">
                  <div className="error-logo mb-0">
                    <Link to={routes.homeOne}>
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/logo.svg"
                        alt="img"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="maintenance-sec">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="maintenance-wrap">
                    <h2>We’re getting ready to launch</h2>
                    <p>
                      We Will be here soon with our new awesome site, subscribe
                      to be notified.
                    </p>
                    <div className="email-notify">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Email Address"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <button type="submit" className="btn btn-primary">
                            Notify Me
                          </button>
                        </div>
                      </div>
                    </div>
                    <h6>Coming Soon</h6>
                    <div className="countdown-container">
                      <div className="countdown-el days-c">
                        <p>90</p>
                        <span>Days</span>
                      </div>
                      <div className="countdown-el hours-c">
                        <p>21</p>
                        <span>Hours</span>
                      </div>
                      <div className="countdown-el mins-c">
                        <p>30</p>
                        <span>Minutes</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 text-center">
                  <ImageWithBasePath
                    src="assets/img/coming-soon.png"
                    className="img-fluid"
                    alt="image"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="coming-back">
                  <p>We Will Be Back Shortly</p>
                  <div className="social-icon media-icon">
                    <ul>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa-brands fa-facebook-f" />{' '}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa-brands fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <i className="fa-brands fa-google" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommingSoon;
