import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const ProviderBookDetails = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const events = [{ title: 'Meeting', start: new Date() }];

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Bookings</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.value)}
                    options={value}
                    optionLabel="name"
                    placeholder="A - Z"
                    className="select provider-select select-space"
                  />
                </div>
                <div className="grid-listview">
                  <ul>
                    <li>
                      <Link to="#">
                        <Icon.Filter className="standard-feather" />
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.providerBookDetails} className="active">
                        <Icon.Calendar className="standard-feather" />
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.providerService}>
                        <Icon.Grid className="standard-feather" />
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.providerBooking}>
                        <Icon.List className="standard-feather" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* calendar */}
          <div className="row">
            <div className="col-md-12">
              <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                weekends={false}
                events={events}
              />
            </div>
          </div>
          {/* /calendar */}
        </div>
      </div>
      {/* Add Leave */}
      <div className="modal fade custom-modal" id="add-reschedule">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Reschedule Appointment</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="standard-feather" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="wallet-add">
                  <div className="form-group">
                    <label className="col-form-label">Appointment Date</label>
                    <div className="form-icon">
                      <input
                        type="text"
                        className="form-control datetimepicker"
                        placeholder="Select appointment date"
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="standard-feather" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Appointment Time</label>
                    <div className="form-icon">
                      <input
                        type="text"
                        className="form-control timepicker"
                        placeholder="Select appointment date"
                      />
                      <span className="cus-icon">
                        <Icon.Clock className="standard-feather" />
                      </span>
                    </div>
                  </div>
                  <div className="modal-submit text-end">
                    <Link
                      to="#"
                      className="btn btn-secondary me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Leave */}
      {/* Alert Message */}
      <div className="modal fade custom-modal" id="alertmsg">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content doctor-profile">
            <div className="modal-header border-bottom-0 justify-content-between">
              <h5 className="modal-title">Cancel Appointment</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="standard-feather" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <form action="#">
                <div className="msg-alert">
                  <p>
                    Are you sure you want to cnacel <span>John Doe</span>{' '}
                    appointment on <span>Oct 28, 2023</span> at time{' '}
                    <span>10AM - 12PM</span>{' '}
                  </p>
                  {/* <p>You wan't be able to revert this action later?</p> */}
                </div>
                <div className="modal-submit text-end">
                  <Link
                    to="#"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Dismiss
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    Yes, cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Alert Message */}
      {/* Booking Details */}
      <div className="toggle-sidebar">
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h5>Booking Details</h5>
            <Link to="#" className="sidebar-close">
              <Icon.X className="standard-feather" />
            </Link>
          </div>
          <div className="sidebar-body">
            <div className="book-confirm bk-wrap">
              <div className="d-flex justify-content-between">
                <h6>
                  Services<span className="badge-success">Confirmed</span>
                </h6>
                <Link to="#" className="edit-book">
                  <Icon.Edit className="standard-feather" />
                </Link>
              </div>
              <ul>
                <li>
                  <span className="bk-date">
                    <Icon.Calendar className="standard-feather" /> Date &amp;
                    Time
                  </span>{' '}
                  : Oct 28, 2023 - 10AM to 12 AM
                </li>
                <li>
                  <span className="bk-date">
                    <Icon.MapPin className="standard-feather" /> Location
                  </span>{' '}
                  : New York
                </li>
                <li>
                  <span className="bk-date">
                    <Icon.User className="standard-feather" /> User Name
                  </span>{' '}
                  : John Smith
                </li>
              </ul>
              <div className="bk-action">
                <Link
                  to="#"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-reschedule"
                >
                  <Icon.User className="standard-feather" /> Reschedule
                </Link>
                <Link
                  to="#"
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#alertmsg"
                >
                  <Icon.XCircle className="standard-feather" /> Cancel
                </Link>
              </div>
            </div>
            <div className="book-customer bk-wrap">
              <h5>Customer Details</h5>
              <div className="d-flex flex-wrap">
                <div className="book-email">
                  <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="User" />
                  <div>
                    <p>John Doe</p>
                    <p>john@example.com</p>
                  </div>
                </div>
                <div className="book-email-info">
                  <div>
                    <p>+1 888 888 8888</p>
                    <p>Montana, USA</p>
                  </div>
                  <Link
                    to="#"
                    className="btn btn-primary btn-mail"
                  >
                    <ImageWithBasePath
                      src="assets/img/icons/message.svg"
                      alt="Message icon"
                    />{' '}
                    Chat
                  </Link>
                </div>
              </div>
            </div>
            <div className="bk-wrap">
              <h5>Appointment Message</h5>
              <p>Thanks for your interest in our services</p>
            </div>
            <div className="bk-wrap bk-service">
              <div>
                <h5>House Cleaning Services</h5>
                <p>quick and quality service</p>
              </div>
              <p className="bk-price">$100.00</p>
            </div>
            <div className="bk-wrap bk-history">
              <h4>Booking History</h4>
              <ul>
                <li>
                  <span>
                    <Icon.Calendar className="standard-feather" />
                  </span>
                  <div className="book-crete">
                    <h6>Booking created</h6>
                    <p>Oct 28 2023 1:28 PM</p>
                  </div>
                </li>
                <li>
                  <span>
                    <Icon.User className="standard-feather" />
                  </span>
                  <div className="book-crete">
                    <h6>Assigned to John Smith</h6>
                    <p>Oct 28 2023 1:28 PM</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* /Booking Details */}
    </div>
  );
};

export default ProviderBookDetails;
