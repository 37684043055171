import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomerDevicemanage } from '../../../../core/models/interface';

const DeviceManagement = () => {
  const data = useSelector(
    (state: any) => state.device_management,
  );
  const statusRender = (rowData: CustomerDevicemanage) => {
    const [status] = rowData.status.split('\n');
    return (
      <Link to="#">
        <span className="badge-danger">{status}</span>
      </Link>
    );
  };

  return (
    <div className="col-lg-9">
      <div className="widget-title">
        <h4>Device Management</h4>
      </div>
      <div className="provide-table manage-table">
        <div className="table-responsive">
          <table className="table custom-table datatable mb-0" id="data-table">
            <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
              value={data}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column sortable field="device" header="Device"></Column>
              <Column sortable field="date" header="Date"></Column>
              <Column sortable field="ipAddress" header="IP Address"></Column>
              <Column sortable field="location" header="Location"></Column>
              <Column
                sortable
                field="status"
                header="Status"
                body={statusRender}
              ></Column>
            </DataTable>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div id="tablelength" />
        </div>
        <div className="col-md-7">
          <div className="table-ingopage">
            <div id="tableinfo" />
            <div id="tablepagination" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceManagement;
